import * as types from './types'

export const triggerConnect = () => {
	return {
		type: types.TRIGGER_CONNECT
	}
}

export const setConnected = (payload) => {
	return {
		type: types.CONNECTED_SUCCESS,
		value: payload
	}
}

export const setDisconnected = () => {
	return {
		type: types.DISCONNECTED_SUCCESS
	}
}

export const switchTheme = (payload) => {
	return {
		type: types.SWITCH_THEME,
		value: payload
	}
}

export const saveUser = (payload) => {
	return {
		type: types.SAVE_USER,
		value: payload
	}
}

export const setGameStatus = (payload) => {
	return {
		type: types.GAME_STATUS,
		value: payload
	}
}

export const isOpenGameExitModal = (payload) => {
	return {
		type: types.GAME_EXIT_MODAL,
		value: payload
	}
}

export const openUrlAfterGameExitModal = (payload) => {
	return {
		type: types.OPEN_URL_AFTER_EXIT,
		value: payload
	}
}
