import React from 'react'
import Header from './Header'
import styled from 'styled-components'
import {v} from '../styles/variables'
import {useSelector} from 'react-redux'

const Layout = ({children}) => {
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	return (
		<SLayout>
			<Header />
			<SMain isDarkTheme={isDarkTheme}>{children}</SMain>
		</SLayout>
	)
}

const SLayout = styled.div`
	display: flex;
`

const SMain = styled.main`
	width: 100%;
	// background: url('/images/bg.png');
`

export default Layout
