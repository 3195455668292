import React from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {palette} from '../assets/common'
import {useSelector} from 'react-redux'
import {Flexed, InputText, Spacer} from '../styles/shared'
import CardButton from '../components/CardButton'

const GamePage = () => {
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	return (
		<>
			<Wrapper fluid>
				<MainContentRow xsJustifyContent="center" smJustifyContent="center" mdJustifyContent="center" lgJustifyContent="start">
					<Col xl={3.4} lg={4} md={6} sm={8} xs={12} xlOffset={1} lgOffset={1} lgOrder={1} mdOrder={2} smdOrder={2} xsOrder={2}>
						{/* <Heading isDarkTheme={isDarkTheme}>Site Name</Heading>
						<Spacer height="1.5" />
						<Text isDarkTheme={isDarkTheme}>Game description here</Text>
						<Spacer height="2" />
						<InputText isDarkTheme={isDarkTheme} placeholder="Email" />
						<Spacer height="2" />
						<CardButton width="100%" isDarkTheme={isDarkTheme} label="Register now!" /> */}
						<Content direction="row" align="center" justify="center">
							<Points align="center">
								<Heading isDarkTheme={isDarkTheme} margin="0" isCentered>
									PLAY
								</Heading>
							</Points>

							<Points align="center">
								<Heading isDarkTheme={isDarkTheme} margin="0" isCentered>
									Earn
								</Heading>
							</Points>

							<Points align="center">
								<Heading isDarkTheme={isDarkTheme} margin="0" isCentered>
									Win
								</Heading>
							</Points>
						</Content>
					</Col>
					<Col xl={6.6} lg={6} sm={8} xs={12} lgOrder={2} mdOrder={1} smdOrder={1} xsOrder={1}>
						<ImageWrapper direction="row">
							<CoverImg src="/images/es_logo.png" />
						</ImageWrapper>
					</Col>
				</MainContentRow>
				{/* <AvtarRow justifyContent="center"> */}
				{/* <Col xl={3.4} lg={3.4} xlOffset={1} lgOffset={1} style={{ position: 'relative' }}>
						<AvtarContent direction="row" align="center">
							<Avtar src="https://chainofalliance.com/static/img/Bard_FemaleHuman_flat-sRUCbM3TnI-1228.webp" />
							<Tag>Our Vision</Tag>
						</AvtarContent>
					</Col> */}
				{/* <Col xl={6.6} lg={6.6}> */}
				{/* <Content direction="row" align="center" justify="center">

					<Points align="center">
					<Heading isDarkTheme={isDarkTheme} margin="0" isCentered>
						PLAY
					</Heading>
					<Text isDarkTheme={isDarkTheme} FontSize={14}>
									Play games
								</Text>
					</Points>
					<Points align="center">
									<LineContent width={'17rem'} isCentered>
										<img src="/images/vertical-img.png" />
									</LineContent>
								</Points>
					<Points align="center">
					<Heading isDarkTheme={isDarkTheme} margin="0" isCentered>
						Earn
					</Heading>
					<Text isDarkTheme={isDarkTheme} FontSize={14}>
									some text here
								</Text>
					</Points>
					<Points align="center">
									<LineContent width={'17rem'} isCentered>
										<img src="/images/vertical-img.png" />
									</LineContent>
								</Points>
					<Points align="center">
					<Heading isDarkTheme={isDarkTheme} margin="0" isCentered>
						Win
					</Heading>
					<Text isDarkTheme={isDarkTheme} FontSize={14}>
									some text here
								</Text>
					</Points>

				</Content> */}
				{/* </Col> */}
				{/* </AvtarRow> */}
			</Wrapper>
		</>
	)
}
const Wrapper = styled(Container)`
	// background: url(/images/cover-img_copy.png);
	// background-size: cover;
	// background-repeat: no-repeat;
	// background-position: 50% 30%;
	position: relative;
	z-index: 2;
`

const ImageWrapper = styled(Flexed)`
	justify-content: center;
	${media.lg`justify-content: flex-end;`};
`

const CoverImg = styled.img`
	height: 13rem;
`

const MainContentRow = styled(Row)`
	padding-top: 3rem;
`

const AvtarRow = styled(Row)`
	padding: 3rem 0;
	background: #09080a;
`

const Heading = styled.h1`
	font-family: 'bicubik', sans-serif;
	letter-spacing: 0.1em;
	font-size: ${({FontSize}) => (FontSize ? `${FontSize}px` : '40px')};
	font-weight: bold;
	display: inline-block;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	min-width: ${({width}) => `${width}`};
	margin: ${({margin}) => `${margin}`};
	text-align: ${({isCentered}) => (isCentered ? `center` : 'inherit')};
	${media.xs` font-size: ${({FontSize}) => (FontSize ? `${FontSize}px` : '20px')};  min-width: ${({width}) => (width ? `9rem` : 'auto')}`};
	${media.sm` font-size: ${({FontSize}) => (FontSize ? `${FontSize}px` : '20px')};  min-width: ${({width}) => (width ? `9rem` : 'auto')}`};
	${media.md` font-size: ${({FontSize}) => (FontSize ? `${FontSize}px` : '30px')};  min-width: ${({width}) => (width ? `13rem` : 'auto')}`};
	${media.lg` font-size: ${({FontSize}) => (FontSize ? `${FontSize}px` : '30px')};  min-width: ${({width}) => (width ? `13rem` : 'auto')}`};
	${media.xl` font-size: ${({FontSize}) => (FontSize ? `${FontSize}px` : '40px')};  min-width: ${({width}) => `${width}`};`}
`

const Text = styled.p`
	font-family: 'Montserrat-Regular', sans-serif;
	letter-spacing: 0.1em;
	font-size: ${({FontSize}) => (FontSize ? `${FontSize}px` : '16px')};
	display: inline-block;
	text-transform: normal;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.black2}`)};
	font-weight: bold;
`

const AvtarContent = styled(Flexed)`
	justify-content: center;
`

const Avtar = styled.img`
	width: 100%;
	position: relative;
	z-index: 2;
	width: 45%;
	padding-bottom: 2rem;
	${media.lg` width:100%;padding-bottom:0rem`};
`

const Content = styled(Flexed)`
	height: 100%;
	${media.xs`padding:1rem`};
	gap: 2rem;
	${media.sm`gap:3rem;`};
`

const Points = styled.div`
	display: flex;
	align-items: center;

	${media.xs`flex-direction:column; & > p{ text-align:center}`};
	${media.sm`flex-direction:row`};
`

const LineContent = styled.div`
	min-width: ${({width}) => `${width}`};
	height: ${({height}) => `${height}`};
	text-align: ${({isCentered}) => (isCentered ? `center` : 'inherit')};
	& > img {
		height: 5.5rem;
		width: 4px;
	}
	${media.sm`min-width: ${({width}) => (width ? `9rem` : 'auto')}`};
	${media.md`min-width: ${({width}) => (width ? `13rem` : 'auto')}`};
	${media.lg`min-width: ${({width}) => (width ? `13rem` : 'auto')}`};
	${media.xl`min-width: ${({width}) => `${width}`};`}
`

const Tag = styled.h1`
	font-family: 'bicubik', sans-serif;
	font-size: 60px;
	transform: rotate(-90deg) translate(5px, -113px);
	color: #244357;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	height: max-content;
	width: max-content;
	z-index: 1;
	font-weight: bold;
	@media screen and (min-width: 0px) and (max-width: 450px) {
		font-size: 30px !important;
		transform: rotate(-90deg) translate(5px, 0px) !important;
	}
	${media.xs`font-size: 40px; transform: rotate(-90deg) translate(5px, 25px);`};
	${media.sm`font-size: 40px; transform: rotate(-90deg) translate(5px, 62px);`};
	${media.md`font-size: 60px;`};
	${media.lg`font-size: 40px;  transform: rotate(-90deg) translate(5px, -113px)`};
	${media.xl`font-size: 60px;  `}
`
export default GamePage
