import styled from 'styled-components'
import {palette} from '../assets/common'
import DateTimePicker from 'react-datetime-picker'

export const Text = styled.p`
	// line-height: ${({lineHeight}) => (lineHeight ? lineHeight : '2.16rem')};
	line-height: ${({xxsmall, xsmall, small, medium, lineHeight}) => (lineHeight ? `${lineHeight}` : xxsmall ? '1rem' : small ? '1.54rem' : medium ? '1.85rem' : xsmall ? '1.2rem' : '2.16rem')};
	font-weight: ${({bold}) => (bold ? 'bold' : 'normal')};
	font-size: ${({xxsmall, xsmall, small, medium}) => (xxsmall ? '0.75rem' : xsmall ? '0.875rem' : small ? '1rem' : medium ? '1.231rem' : '1.39rem')};
	font-family: ${({font}) => font};
	// color: ${({color}) => palette[color]};
	color: ${({color, isDarkTheme}) => (color ? palette[color] : isDarkTheme ? palette.off_white : palette.off_dark)};
	margin: ${({margin}) => `${margin}`};
	text-align: ${({isCentered}) => (isCentered ? `center` : 'inherit')};
	cursor: ${({pointer}) => (pointer ? `pointer` : 'inherit')};
`

export const Flexed = styled.div`
	display: ${({inline}) => (inline ? 'inline-flex' : 'flex')};
	flex-direction: column;
	flex-direction: ${({direction}) => direction};
	align-items: ${({align}) => align};
	justify-content: ${({justify}) => justify};
	margin: ${({margin}) => `${margin}`};
	gap: ${({ gap }) => `${gap}rem`};
	flex-wrap: ${({ flexWrap }) => `${flexWrap}`};
`

export const Divider = styled.hr`
	height: 0 !important;
	border: 0;
	border-top: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.gray}` : `${palette.gray_light}`)};

	margin: ${({margin}) => `${margin}`};
`

export const Spacer = styled.div`
	height: ${({height}) => `${height}rem`};
`

export const InputText = styled.input`
	outline: none;
	disabled: ${({disabled}) => (disabled === true ? true : false)};
	width: 100%;
	font-size: 1rem;
	padding: 0.5rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.off_dark}`)};
	// background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	background: transparent;
	border: 0.09rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
	border-radius: 0.3rem;
	box-sizing: border-box;
	::placeholder {
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
		opacity: 1; /* Firefox */
		font-size: 0.9rem;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
		font-size: 0.9rem;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
		font-size: 0.9rem;
	}
`
export const TextArea = styled.textarea`
	outline: none;
	width: 100%;
	font-size: 1rem;
	padding: 0.5rem;
	border: 0.09rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
	border-radius: 0.3rem;
	box-sizing: border-box;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.off_dark}`)};
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	::placeholder {
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
		opacity: 1; /* Firefox */
		font-size: 0.9rem;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
		font-size: 0.9rem;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
		font-size: 0.9rem;
	}
`

export const InputDate = styled(DateTimePicker)`
	outline: none;
	width: 100%;
	font-size: 1rem;
	padding: 0.2rem;
	border: 0.09rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.gray}`)};
	border-radius: 0.3rem;
	box-sizing: border-box;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.off_dark}`)};
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	& input {
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.off_dark}`)};
	}
	& > div > button > svg {
		stroke: ${({isDarkTheme}) => (isDarkTheme ? `${palette.dark_gray}` : `${palette.off_dark}`)} !important;
	}
	& div {
		border: 0rem !important;
	}
	::placeholder {
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
		opacity: 1; /* Firefox */
		font-size: 0.9rem;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
		font-size: 0.9rem;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.charcol}`)};
		font-size: 0.9rem;
	}
`
