import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { connect } from 'react-redux'

import { setConnected, setDisconnected, saveUser, setGameStatus, isOpenGameExitModal, openUrlAfterGameExitModal } from '../actions/authActions'
import { connectWithMetamask } from '../hooks/contracts'

import { toast } from 'react-toastify'
import { palette } from '../assets/common'
import { useNavigate } from 'react-router-dom'
import CardButton, { handleBackgroundType, handleBorderType } from '../components/CardButton'
import { useSelector, useDispatch } from 'react-redux'
import { BsFillSunFill, BsMoonFill, BsXLg } from 'react-icons/bs'
import { AiOutlineLoading3Quarters, AiOutlineMenu } from 'react-icons/ai'

import { useLocation } from 'react-router-dom'
import { Divider, Flexed, Spacer, Text } from '../styles/shared'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { isTestNet } from '../config/config'
import { MdOutlineClose } from 'react-icons/md'
import { PiInfoBold } from 'react-icons/pi'

const Header = (props) => {
	const { pathname } = useLocation()
	const navigate = useNavigate()

	const _isConnected = useSelector((state) => state.auth.isConnected)
	const user = useSelector((state) => state.auth.user)
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	const gameStatus = useSelector((state) => state.auth.isGameInProgress)
	const exitGameModalStatus = useSelector((state) => state.auth.isExitGameModalOpen)
	const goToThisUrl = useSelector((state) => state.auth.urlAfterGameExit)
	const [openHowConnectMetaMaskModal, setOpenHowConnectMetaMaskModal] = useState(false)

	const dispatch = useDispatch()

	const [isConnected, setIsConnected] = useState(false)
	const [address, setAddress] = useState('')
	const [processing, setProcessing] = useState(false)
	const [navMenu, setNavMenu] = useState(false)
	const [scrollPosition, setScrollPosition] = useState(0)
	const [activeDisconnect, setActiveDisconnect] = useState(false)

	const [size, setSize] = useState({
		x: window.innerWidth,
		y: window.innerHeight
	})
	const updateSize = () =>
		setSize({
			x: window.innerWidth,
			y: window.innerHeight
		})
	useEffect(() => (window.onresize = updateSize), [])
	useEffect(() => {
		if (size.x > 1200) {
			setNavMenu(false)
		}
	}, [size])

	useEffect(() => {
		if (_isConnected) {
			setIsConnected(true)
			setAddress(user.token)
		}
	}, [_isConnected])

	const handleDisconnect = async () => {
		setIsConnected(false)
		props.setDisconnected()
		navigate('/')
	}

	const handleConnect = async () => {
		connectWithMetamask(setProcessing, setAddress, setIsConnected, handleDisconnect, props, toast)
		// try {
		// 	// const {default: MewConnectImport} = await import('@myetherwallet/mewconnect-web-client')
		// 	const infuraId = 'e7218ebfa4cd4b65bd3d4c5a508f03a8' //todo..replace this id
		// 	const providerOptions = {
		// 		walletconnect: {
		// 			package: WalletConnectProvider,
		// 			options: {
		// 				infuraId
		// 			}
		// 		},
		// 		// mewconnect: {
		// 		// 	package: MewConnectImport,
		// 		// 	options: {
		// 		// 		infuraId
		// 		// 	}
		// 		// },
		// 		'custom-coinbase': {
		// 			display: {
		// 				logo: '/images/coinbase_wallet.png',
		// 				name: 'Coinbase',
		// 				description: 'Scan with WalletLink to connect'
		// 			},
		// 			package: WalletLink,
		// 			options: {
		// 				appName: 'SSS Games',
		// 				networkUrl: `https://mainnet.infura.io/v3/${infuraId}`,
		// 				chainId: 1
		// 			},
		// 			connector: async (_, options) => {
		// 				const {appName, networkUrl, chainId} = options
		// 				const walletLink = new WalletLink({
		// 					appName
		// 				})
		// 				const provider = walletLink.makeWeb3Provider(networkUrl, chainId)
		// 				await provider.enable()
		// 				return provider
		// 			}
		// 		}
		// 	}
		// 	const web3Modal = new Web3Modal({
		// 		network: 'mainnet',
		// 		cacheProvider: true,
		// 		providerOptions
		// 	})
		// 	const web3 = await web3Modal.connect()
		// 	const provider = new ethers.providers.Web3Provider(web3)
		// 	const signer = provider.getSigner()
		// 	let address = await signer.getAddress()
		// 	address = address.toLowerCase()
		// 	// const chainId = await signer.getChainId()
		// 	const {chainId, name} = await provider.getNetwork()
		// 	// if (chainId === 1 || name === 'homestead' || name === 'mainnet') {
		// 	if (chainId.toString() == '97' || name === 'testnet') {
		// 		const tokenContract = new ethers.Contract(SSSTokenContract.id, SSSTokenContract.abi, signer)
		// 		let user = await doLogin(address, tokenContract)
		// 		setProcessing(false)
		// 		if (user) {
		// 			user.coins = parseFloat(user.coins)
		// 			const isConnected = Boolean(provider && signer)
		// 			setIsConnected(isConnected)
		// 			setAddress(address)
		// 			props.setConnected({
		// 				isConnected: isConnected,
		// 				user: user,
		// 				signer: signer,
		// 				// address: address,
		// 				tokenContract: tokenContract
		// 			})
		// 			toast.success('Wallet connected successfully')
		// 			web3.on('accountsChanged', (accounts) => {
		// 				handleDisconnect()
		// 			})
		// 			web3.on('chainChanged', (chainId) => {
		// 				handleDisconnect()
		// 			})
		// 			web3.on('disconnect', (error) => {
		// 				handleDisconnect()
		// 			})
		// 		} else {
		// 			toast.error('Server login error. Please try again later')
		// 			props.setConnected({
		// 				isConnected: false
		// 			})
		// 		}
		// 	} else {
		// 		toast.error('Please connect to chain id 97')
		// 		props.setConnected({
		// 			isConnected: false
		// 		})
		// 	}
		// } catch (error) {
		// 	console.log('error', error)
		// 	props.setConnected({
		// 		isConnected: false
		// 	})
		// }
	}

	const handleScroll = () => {
		const _position = window.pageYOffset
		setScrollPosition(_position)
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true })
	}, [])

	return (
		<>
			<Main
				isDarkTheme={isDarkTheme}
				styled={{
					boxShadow: '0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%)'
				}}
				scroll={scrollPosition}>
				<Row>
					<NavContent>
						<LogoContent
							onClick={() => {
								gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/'))) : navigate('/')
							}}>
							{isTestNet && (
								<BarBadgeWrapper testNetBadge={true} id="ddd">
									<BarBadge src="/images/testnet.svg" testNetBadge={true} />
								</BarBadgeWrapper>
							)}
							<Logo src="/images/smart_soft_logo.png" alt="logo" />
							<LogoText>
								<BrandName isDarkTheme={isDarkTheme}>
									PlayRise&nbsp;<span>Ventures</span>
								</BrandName>
								<TagLine isDarkTheme={isDarkTheme}>
									Play <span>2</span> Earn
								</TagLine>
							</LogoText>
						</LogoContent>

						<Flexed direction="row" align="center">
							<Menu
								isActive={pathname === '/'}
								isDarkTheme={isDarkTheme}
								onClick={() => {
									gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/'))) : navigate('/')
								}}>
								Home
							</Menu>
							<Menu
								isActive={pathname === '/tokens'}
								isDarkTheme={isDarkTheme}
								onClick={() => {
									gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/tokens'))) : navigate('/tokens')
								}}>
								Venture Tokens
							</Menu>
							<Menu
								isActive={pathname === '/mobile_tokens'}
								isDarkTheme={isDarkTheme}
								onClick={() => {
									gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/mobile_tokens'))) : navigate('/mobile_tokens')
								}}>
								M-Venture
							</Menu>
							<Menu
								isActive={pathname === '/packages'}
								isDarkTheme={isDarkTheme}
								onClick={() => {
									gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/packages'))) : navigate('/packages')
								}}>
								Packages
							</Menu>
							<MenuLink isDarkTheme={isDarkTheme} target="_blank" href="https://mp.playriseventures.com/">
								Marketplace
							</MenuLink>
							{isConnected && (
								<Menu
									isActive={pathname === '/profile'}
									isDarkTheme={isDarkTheme}
									onClick={() => {
										gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/profile'))) : navigate('/profile')
									}}>
									My Profile
								</Menu>
							)}
							<DropMenu isConnected={isConnected}>
								{/* <ButtonWrapper>
									<ConnectButton type="primary" isDarkTheme={isDarkTheme} disabled={processing} onMouseEnter={() => setNavMenu(false)} onClick={() => (!isConnected && !processing ? handleConnect() : gameStatus ? dispatch(isOpenGameExitModal(true), setActiveDisconnect(true)) : handleDisconnect())}>
										{!processing ? (
											<>{!isConnected ? <div className={`${!isConnected ? 'shimmer' : ''}`}> {'Connect'} </div> : <> {address} </>}</>
										) : (
											<span>
												<Processing /> &nbsp;Connecting...
											</span>
										)}
									</ConnectButton>
								</ButtonWrapper> */}
								<ButtonWrapper>
									<ConnectButton
										type="primary"
										isDarkTheme={isDarkTheme}
										onMouseEnter={() => setNavMenu(false)}
										onClick={() => (!isConnected && !processing ? handleConnect() : gameStatus ? dispatch(isOpenGameExitModal(true), setActiveDisconnect(true)) : handleDisconnect())}>
										{!isConnected ? <div className='shimmer'>Connect</div> : 'Disconnect'}
									</ConnectButton>
								</ButtonWrapper>
								{/* <Drop id="dropdown" isDarkTheme={isDarkTheme}>
									<DropList
										isActive={pathname === '/tokens'}
										isDarkTheme={isDarkTheme}
										onClick={() => {
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/tokens'))) : navigate('/tokens')
										}}>
										<Flexed direction="row" align="center">
											<Icon isDarkTheme={isDarkTheme} />
											<Text small bold isDarkTheme={isDarkTheme}>
												SSS Tokens:
											</Text>
										</Flexed>
										<Text small margin="0.5rem 0rem 0rem 0rem !important" bold isDarkTheme={isDarkTheme}>
											{props.user?.sssTokens ? props.user?.sssTokens : 0}
										</Text>
										<Text small bold color="charcol">
											~0 BNB
										</Text>
									</DropList>
									<DropList
										isActive={pathname === '/mobile_tokens'}
										isDarkTheme={isDarkTheme}
										onClick={() => {
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/mobile_tokens'))) : navigate('/mobile_tokens')
										}}>
										<Flexed direction="row" align="center">
											<Icon isDarkTheme={isDarkTheme} />
											<Text small bold isDarkTheme={isDarkTheme}>
												M-Venture Tokens:
											</Text>
										</Flexed>
										<Text small margin="0.5rem 0rem 0rem 0rem !important" bold isDarkTheme={isDarkTheme}>
											{props.user?.coins ? props.user?.coins : 0}
										</Text>
										<Text small bold color="charcol">
											~0 BNB
										</Text>
									</DropList>
									<DropList
										isActive={pathname === '/profile'}
										isDarkTheme={isDarkTheme}
										onClick={() => {
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/profile'))) : navigate('/profile')
										}}>
										<Text small bold isDarkTheme={isDarkTheme}>
											My Profile
										</Text>
									</DropList>
									<DropList to={pathname} onClick={() => (!isConnected && !processing ? handleConnect() : gameStatus ? dispatch(isOpenGameExitModal(true), setActiveDisconnect(true)) : handleDisconnect())} isDarkTheme={isDarkTheme}>
										<Text small bold isDarkTheme={isDarkTheme}>
											Disconnect
										</Text>{' '}
									</DropList>
								</Drop> */}
							</DropMenu>
							<PiInfoBold cursor="pointer" onClick={() => setOpenHowConnectMetaMaskModal(true)} fontSize={20} color={palette.aqua_blue_bright} />
							{/* <Mode>
								{isDarkTheme ? (
									<Cover
										isDarkTheme={isDarkTheme}
										isActive={isDarkTheme}
										onClick={() => {
											dispatch(switchTheme(false))
										}}>
										<BsFillSunFill />
									</Cover>
								) : (
									<Cover
										isDarkTheme={isDarkTheme}
										isActive={!isDarkTheme}
										onClick={() => {
											dispatch(switchTheme(true))
										}}>
										<BsMoonFill />
									</Cover>
								)}
							</Mode> */}
							<MenuToggle>
								<MenuToggleBtn
									navMenu={navMenu}
									onClick={() => {
										setNavMenu(!navMenu)
									}}
									isDarkTheme={isDarkTheme}>
									{navMenu ? <BsXLg /> : <AiOutlineMenu />}
								</MenuToggleBtn>
								<MenuDropDown id="nav_responsive_menu" isDarkTheme={isDarkTheme} navMenu={navMenu}>
									<DropList
										onClick={() => {
											setNavMenu(false)
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/'))) : navigate('/')
										}}
										isActive={pathname === '/'}
										isDarkTheme={isDarkTheme}>
										<Text small bold isDarkTheme={isDarkTheme}>
											Home
										</Text>
									</DropList>
									<DropList
										onClick={() => {
											setNavMenu(false)
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/tokens'))) : navigate('/tokens')
										}}
										isActive={pathname === '/tokens'}
										isDarkTheme={isDarkTheme}>
										<Text small bold isDarkTheme={isDarkTheme}>
											Venture Tokens
										</Text>
									</DropList>
									<DropList
										onClick={() => {
											setNavMenu(false)
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/mobile_tokens'))) : navigate('/mobile_tokens')
										}}
										isActive={pathname === '/mobile_tokens'}
										isDarkTheme={isDarkTheme}>
										<Text small bold isDarkTheme={isDarkTheme}>
											M-Venture
										</Text>
									</DropList>
									<DropList
										onClick={() => {
											setNavMenu(false)
											gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/packages'))) : navigate('/packages')
										}}
										isActive={pathname === '/packages'}
										isDarkTheme={isDarkTheme}>
										<Text small bold isDarkTheme={isDarkTheme}>
											Packages
										</Text>
									</DropList>
									<DropList
										target="_blank"
										href="https://mp.playriseventures.com/"
										isDarkTheme={isDarkTheme}
										onClick={() => {
											setNavMenu(false)
										}}>
										<Text small bold isDarkTheme={isDarkTheme}>
											Marketplace
										</Text>{' '}
									</DropList>
									{isConnected && (
										<DropListLink
											isActive={pathname === '/profile'}
											isDarkTheme={isDarkTheme}
											onClick={() => {
												setNavMenu(false)
												gameStatus ? (dispatch(isOpenGameExitModal(true)), dispatch(openUrlAfterGameExitModal('/profile'))) : navigate('/profile')
											}}>
											<Text small bold isDarkTheme={isDarkTheme}>
												Profile
											</Text>{' '}
										</DropListLink>
									)}
								</MenuDropDown>
							</MenuToggle>
						</Flexed>
					</NavContent>
				</Row>
			</Main>
			<Modal
				open={exitGameModalStatus}
				onClose={() => {
					dispatch(isOpenGameExitModal(false))
				}}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal',
					closeButton: 'closeModel'
				}}
				closeOnOverlayClick={false}
				showCloseIcon={false}>
				<Head direction="row" align="center" justify="center">
					<Text type="large" lineHeight="1.438" fontWeight="700" color="aqua_blue_bright">
						Exit Game
					</Text>
					{/* <Close onClick={() => {
						dispatch(isOpenGameExitModal(false))
					}} /> */}
				</Head>
				<Divider isDarkTheme={isDarkTheme} />
				<Container>
					<Row>
						<Col>
							<Spacer height={0.2} />
							<Text isDarkTheme={isDarkTheme} isCentered>
								Game is in progress.
								<br /> Are you sure you want to Exit ?
							</Text>
							<Spacer height={0.5} />
						</Col>
					</Row>
				</Container>
				<Divider isDarkTheme={isDarkTheme} />
				<Container>
					<Row>
						<Col>
							<Flexed direction="row" justify="center" gap={1.5}>
								<CardButton
									type="blue"
									isDarkTheme={isDarkTheme}
									label="No"
									ifClicked={() => {
										dispatch(isOpenGameExitModal(false))
									}}
								/>
								<CardButton
									type="red"
									isDarkTheme={isDarkTheme}
									label="Yes"
									ifClicked={() => {
										dispatch(setGameStatus(false))
										dispatch(isOpenGameExitModal(false))
										activeDisconnect ? handleDisconnect() : navigate(goToThisUrl)
									}}
								/>
							</Flexed>
						</Col>
					</Row>
				</Container>
			</Modal>
			<Modal
				open={openHowConnectMetaMaskModal}
				onClose={() => {
					setOpenHowConnectMetaMaskModal(false)
				}}
				center
				classNames={{
					overlay: 'howConnectMetaMaskOverLay',
					modal: 'howConnectMetaMaskModal',
					closeButton: 'closeModel'
				}}
				closeOnOverlayClick={false}
				showCloseIcon={false}>
				<Head direction="row" align="center" justify="space-between">
					<Text type="large" lineHeight="1.438" fontWeight="700" color="aqua_blue_bright">
						How to connect the Mata Mask
					</Text>
					<Close
						onClick={() => {
							setOpenHowConnectMetaMaskModal(false)
						}}
					/>
				</Head>
				<Divider isDarkTheme={isDarkTheme} />
				<Container>
					<Row>
						<Col>
							<Spacer height={0.2} />
							<Flexed gap={0.2}>
								<Text bold lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
									Add MetMask Extension to Chrome
								</Text>
								<Text
									pointer
									onClick={() => {
										const _link = `https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?pli=1`
										window.open(_link, '_blank')
									}}
									lineHeight="none"
									xsmall
									isDarkTheme={isDarkTheme}
									color="aqua_blue">
									https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?pli=1
								</Text>
							</Flexed>
							<Spacer height={0.7} />
							<Flexed gap={0.2}>
								<Text bold lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
									Switch network to BNB TestNet
								</Text>
								<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
									description 1-- description 1 description 1 — description 1-- description 1 description 1 --description 1-- description 1 description 1 --description 1-- description 1 description 1 --description 1-- description 1 description 1
								</Text>
							</Flexed>
							{/* <Spacer height={0.7} />
							<Flexed gap={0.2}>
								<Text bold lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
									Step-2
								</Text>
								<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
									description 1-- description 1 description 1 — description 1-- description 1 description 1 --description 1-- description 1 description 1 --description 1-- description 1 description 1 --description 1-- description 1 description 1
								</Text>
							</Flexed>
							<Spacer height={0.7} />
							<Flexed gap={0.2}>
								<Text bold lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
									Step-3
								</Text>
								<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
									description 1-- description 1 description 1 — description 1-- description 1 description 1 --description 1-- description 1 description 1 --description 1-- description 1 description 1 --description 1-- description 1 description 1
								</Text>
							</Flexed>
							<Spacer height={0.5} /> */}
						</Col>
					</Row>
				</Container>
				<Divider isDarkTheme={isDarkTheme} />
				<Container>
					<Row>
						<Col>
							<Flexed direction="row" justify="flex-end" gap={1.5}>
								{/* <CardButton
									type="blue"
									isDarkTheme={isDarkTheme}
									label="Close"
									ifClicked={() => {
										setOpenHowConnectMetaMaskModal(false)
									}}
								/> */}
								<CardButton
									type="red"
									isDarkTheme={isDarkTheme}
									label="Close"
									ifClicked={() => {
										setOpenHowConnectMetaMaskModal(false)
									}}
								/>
							</Flexed>
						</Col>
					</Row>
				</Container>
			</Modal>
		</>
	)
}

const Main = styled(Container)`
	position: fixed;
	background: ${({ scroll }) => (scroll == 0 ? 'rgba(4, 6, 9, 1)' : 'rgb(4, 6 , 9, 0.8)')};
	z-index: 9;
	max-width: 100%;
	box-shadow: 0px 3px 15px rgba(255, 255, 255, 0.2);
`

const NavContent = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 5rem;
	padding: 0rem 3rem 0rem 3rem;
	${media.xs`
    padding: 0rem 1rem 0rem 1rem;
  `}
	${media.sm`
    padding: 0rem 1rem 0rem 1rem;
  `}
`

const LogoContent = styled.div`
	padding: 0rem;
	display: flex;
	align-items: center;
`
const Logo = styled.img`
	width: 3.5rem;
	padding: 0rem;
	cursor: pointer;
`

const LogoText = styled.span`
	margin-left: 0.5rem;
	cursor: pointer;
	${media.xs`
    	display:none;
  `}
	${media.sm`
   		display:block;
   `}
`

const BrandName = styled.p`
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.black}`)};
	font-size: 1.2rem;
	// font-size: 1.5rem;
	font-weight: bold;
	font-family: 'bicubik';
	display: flex;
	justify-content: center;
	alignc-items: center;
	letter-spacing: 0.1rem;
	margin-top: -0.3rem;
	font-family: 'frozen', sans-serif;
	& span {
		color: ${palette.aqua_blue};
	}
`
const TagLine = styled.p`
	color: ${palette.aqua_blue};
	font-family: 'bicubik';
	// font-size: 0.7rem;
	font-size: 0.8rem;
	// margin-top: -1.2rem;
	letter-spacing: 0.12rem;
	text-transform: uppercase;
	text-align: center;
	font-family: 'frozen', sans-serif;
	& span {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.black}`)};
	}
`
const ButtonWrapper = styled.div`
	position: relative;
	transition: all 0.5s ease-in-out;
	width: fit-content;
	&::before {
		position: absolute;
		transform: matrix(1, 0.05, 0.01, 1, 0, 0);
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0;
		background: ${palette.blue_gradient_shadow};
		filter: blur(7.60982px);
		border-radius: 2rem;
	}
	&:hover {
		&::before {
			background: none;
			filter: blur(7.60982px);
			border-radius: 2rem;
		}
	}
`

const ConnectButton = styled.div`
	position: relative;
	font-family: 'bicubik';
	letter-spacing: 0.15em;
	font-size: 0.8rem;
	background: ${({ type, isDarkTheme }) => (isDarkTheme ? `${palette.black2}` : handleBackgroundType(type))};
	display: inline-block;
	margin: 0.4rem 0.5rem 0 0.5rem;
	padding: 0rem 1rem;
	height: 40px;
	line-height: 40px;
	font-weight: 600;
	border: 1px solid ${({ type }) => handleBorderType(type)};
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.white}`)};
	border-radius: 2rem;
	text-align: center;
	cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
	text-overflow: ellipsis;
	overflow: hidden;
	min-width: 8.75rem;
	max-width: 12rem;
	white-space: nowrap;
	transition: all 0.5s ease-in-out;
	&:hover {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_black}` : `${palette.white}`)};
		background: ${({ isDarkTheme, type }) => (isDarkTheme ? handleBackgroundType(type) : `${palette.off_black}`)};
	}
	&:hover .shimmer {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_black}` : `${palette.white}`)} !important;
	};
`
const spinnerAnimation = keyframes`
	to { -webkit-transform: rotate(360deg); }
`

const Processing = styled(AiOutlineLoading3Quarters)`
	animation: ${spinnerAnimation} 1.5s linear infinite;
`

const Menu = styled.div`
	color: ${({ isDarkTheme, isActive }) => (isActive == true ? (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`) : isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
	text-decoration: none;
	padding: 0rem 0.5rem;
	cursor: pointer;
	font-family: 'Montserrat-Medium', sans-serif;
	text-transform: uppercase;
	font-size: 0.9rem;
	&:hover {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	}
	${media.xs`
		display: none;
    `};
	${media.sm`
		display: none;
    `};
	${media.md`
		display: none;
    `};
	${media.lg`
		display: none;
    `};
	${media.xl`
		display: block;
    `};
`
const MenuLink = styled.a`
	color: ${({ isDarkTheme, isActive }) => (isActive == true ? (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`) : isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
	text-decoration: none;
	padding: 0rem 0.5rem;
	cursor: pointer;
	font-family: 'Montserrat-Medium', sans-serif;
	text-transform: uppercase;
	font-size: 0.9rem;
	&:hover {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	}
	${media.xs`
		display: none;
    `};
	${media.sm`
		display: none;
    `};
	${media.md`
		display: none;
    `};
	${media.lg`
		display: none;
    `};
	${media.xl`
		display: block;
    `};
`

const Mode = styled.div`
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
	cursor: pointer;
`

const Cover = styled.div`
	padding: 0.04rem 0.28rem;
	font-size: 1.2rem;
	border-radius: 0.2rem;
	color: ${({ isDarkTheme, isActive }) => (isDarkTheme ? (isActive ? `${palette.aqua_blue}` : `${palette.yellow}`) : isActive ? `${palette.blue}` : `${palette.off_dark}`)};
	// background: ${({ isDarkTheme, isActive }) => (isDarkTheme ? (isActive ? `${palette.green_gradient}` : `${palette.black}`) : isActive ? `${palette.blue_mirror}` : `${palette.white}`)};
	cursor: pointer;
`

const DropMenu = styled.div`
	position: relative;
	&:hover #dropdown {
		display: ${({ isConnected }) => (isConnected ? `block` : `none`)};
	}
`

const Drop = styled.div`
	display: none;
	position: absolute;
	background-color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	min-width: 16rem;
	padding: 0.5rem;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	right: 0;
	border-radius: 1.2rem;
`

const DropList = styled.div`
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
	border-radius: 1.2rem;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	text-align: left;
	margin-bottom: 0.2rem;
	font-weight: bold;
	background: ${({ isDarkTheme, isActive }) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};

	& p {
		color: ${({ isDarkTheme, isActive }) => (isActive ? (isDarkTheme ? `${palette.off_black}` : `${palette.blue}`) : isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
		cursor: pointer;
	}

	&:hover {
		background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`)};
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_black}` : `${palette.blue}`)};
		cursor: pointer;
	}
	&:hover p {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_black}` : `${palette.blue}`)};
		cursor: pointer;
	}
`

const DropListLink = styled.a`
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
	border-radius: 1.2rem;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	text-align: left;
	margin-bottom: 0.2rem;
	font-weight: bold;
	background: ${({ isDarkTheme, isActive }) => (isActive ? (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`) : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};

	& p {
		color: ${({ isDarkTheme, isActive }) => (isActive ? (isDarkTheme ? `${palette.off_black}` : `${palette.blue}`) : isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
		cursor: pointer;
	}

	&:hover {
		background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.gray_gradient}` : `${palette.blue_mirror}`)};
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_black}` : `${palette.blue}`)};
		cursor: pointer;
	}
	&:hover p {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.off_black}` : `${palette.blue}`)};
		cursor: pointer;
	}
`

const Icon = styled.div`
	background: ${({ src, isDarkTheme }) => (src ? `url(${src}) no-repeat` : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	background-size: cover;
	background-position: center;
	width: 1.75rem;
	height: 1.75rem;
	border-radius: 5rem;
	border: 0.063rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	margin-right: 0.3rem;
`

const MenuToggle = styled.div`
	width: fit-content;
	position: relative;
	// display: none;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
	cursor: pointer;
	${media.xs`
		display: flex;
    `};
	${media.sm`
		display: flex;
    `};
	${media.md`
		display: flex;
    `};
	${media.lg`
		display: flex;
    `};
	${media.xl`
		display: none;
    `};
`

const MenuToggleBtn = styled.div`
	padding: 0.04rem 0.28rem;
	font-size: ${({ navMenu }) => (navMenu ? '1.3rem' : '1.5rem')};
	cursor: pointer;
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.white}` : `${palette.off_dark}`)};
`

const MenuDropDown = styled.div`
	display: ${({ navMenu }) => (navMenu ? 'block' : 'none')};
	position: absolute;
	background-color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	min-width: 16rem;
	padding: 0.5rem;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	right: 0;
	top: 3rem;
	border-radius: 1.2rem;
`

const BarBadgeWrapper = styled.div`
	position: absolute;
	right: ${({ testNetBadge }) => (testNetBadge ? `` : `0rem`)};
	top: ${({ testNetBadge }) => (testNetBadge ? `` : `-0.188rem`)};
	transform: ${({ testNetBadge }) => (testNetBadge ? `rotate(0deg)` : `rotate(90deg)`)};
	left: -3px;
`

const BarBadge = styled.img`
	width: ${({ testNetBadge }) => (testNetBadge ? `100%` : `4.5rem`)};
`

const Head = styled(Flexed)`
	padding: 0rem 1rem;
`

const Close = styled(MdOutlineClose)`
	color: ${palette.dark};
	background-color: ${palette.aqua_blue_bright};
	font-size: 1.5rem;
	cursor: pointer;
	z-index: 1;
`

const mapStateToProps = (state) => {
	return {
		user: state.auth.user
	}
}

const mapDispatchToProps = (dispatch) => ({
	setConnected: (data) => dispatch(setConnected(data)),
	setDisconnected: (data) => dispatch(setDisconnected(data)),
	saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
