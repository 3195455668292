import React, {useState, useEffect} from 'react'
import styled, {keyframes} from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {palette} from '../assets/common'
import {useSelector} from 'react-redux'
import {Flexed, Spacer, Text} from '../styles/shared'
import {BsFiles} from 'react-icons/bs'
import Chart from 'react-google-charts'
import {SSSTokenContract} from '../contracts/SSSTokenContract'
import {ethers} from 'ethers'
import Web3 from 'web3'

const Tokens = () => {
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	const [address, setAddress] = useState('0x8D27663b363783648eCaa31d0aB2C082c2aE3732')
	const [toolTipText, setToolTipText] = useState(false)

	const [tokenName, setTokenName] = useState('Venture')
	const [tokenSymbol, setTokenSymbol] = useState('VEN')
	const [decimals, setDecimals] = useState(18)
	const [totalSupply, setTotalSupply] = useState(0)

	const [pieData, setPieData] = useState([
		['Task', 'Hours per Day'],
		['Presale', 11],
		['Liquidty', 2],
		['Unlocked', 2]
	])

	const pieOptions = {
		title: '',
		is3D: true,
		width: 300,
		height: 280,
		chartArea: {width: '100%', height: '80%'},
		legend: {position: 'bottom'},
		...(isDarkTheme ? {backgroundColor: '#2e2d2e'} : {backgroundColor: '#f5f5f5'}),
		...(isDarkTheme ? {legendTextStyle: {color: `${palette.off_white}`}} : {legendTextStyle: {color: `${palette.off_dark}`}})
	}

	useEffect(() => {
		getTokenMetrcis()
	}, [])

	const getTokenMetrcis = async () => {
		const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/d1904153f4824e368e41ecf3ac0d6418')
		const readTokenContract = new ethers.Contract(SSSTokenContract.id, SSSTokenContract.abi, provider)
		try {
			console.log(readTokenContract)
			let name = await readTokenContract.name()
			setTokenName(name)

			let symbol = await readTokenContract.symbol()
			setTokenSymbol(symbol)

			let totalSupply = await readTokenContract.totalSupply()
			setTotalSupply(totalSupply)

			let decimals = await readTokenContract.decimals()
			setDecimals(decimals)
		} catch (error) {
			console.log(error?.message)
		}
	}

	const buySSST = async () => {
		window.open('https://pancake.kiemtienonline360.com/#/swap')
	}

	const copy = async () => {
		await navigator.clipboard.writeText(address)
		setToolTipText(true)
		setTimeout(() => {
			setToolTipText(false)
		}, 300)
	}

	const [ssstDetails, setSSSTDetails] = useState([
		{
			id: 1,
			notch: '/images/mini_pink_notch.svg',
			backGround: 'pink_mirror',
			name: 'Stack',
			iconSection: '/images/icon_pink_section.png',
			icon: '/images/stack.png',
			description: 'Venture tokens can be purchased for investment purposes.'
		},
		{
			id: 2,
			notch: '/images/mini_blue_notch.svg',
			backGround: 'blue_mirror',
			name: 'Play & Earn',
			iconSection: '/images/icon_blue_section.png',
			icon: '/images/earn.png',
			description: 'You can earn Venture tokens through participation in competitions and challenges.'
		},
		{
			id: 3,
			notch: '/images/mini_orange_notch.svg',
			backGround: 'orange_mirror',
			name: 'Convert',
			iconSection: '/images/icon_orange_section.png',
			icon: '/images/swap.png',
			description: 'Players can convert Venture tokens into M-Venture to play games on mobile.'
		}
	])

	return (
		<Wrapper>
			<Row>
				<Col xl={8} xxl={8}>
					<Form isDarkTheme={isDarkTheme} padding="1rem 2rem 2rem">
						<Row>
							<Col lg={12}>
								<HeadingLightEffect isDarkTheme={isDarkTheme} />
								<Heading isDarkTheme={isDarkTheme}>Venture Tokens?</Heading>
								<Spacer height={1} />
								<Text small isDarkTheme={isDarkTheme} isCentered>
									You require Venture tokens to participate in games on this platform.
								</Text>
							</Col>
						</Row>
						<Spacer height={1} />
						<Block>
							{ssstDetails.map((info, index) => {
								return (
									<Card key={index + 2}>
										<NotchImg src={info?.notch} alt="notch" />
										<CardBody backGround={info?.backGround}>
											<Spacer height={2} />
											<Text small isCentered color={isDarkTheme ? 'white' : 'black'}>
												{info?.name}
											</Text>
											<Spacer height={1} />
											<Flexed direction="row" justify="center" align="center">
												<IconDisplay src={info?.iconSection} />
												<Image isDarkTheme={isDarkTheme} src={info?.icon} />
											</Flexed>
											<Spacer height={1.5} />
											<Text xxsmall isDarkTheme={isDarkTheme} isCentered>
												{info?.description}
											</Text>
											<Spacer height={2.5} />
										</CardBody>
										<NotchImgBottom src={info?.notch} alt="bottom-notch" />
									</Card>
								)
							})}
						</Block>
						<HeadingLightEffect isDarkTheme={isDarkTheme} />
						<Heading isDarkTheme={isDarkTheme}>Venture tokens are available on</Heading>
						<Spacer height={1} />
						<Block>
							<Card>
								<NotchImg src={'/images/mini_green_notch.svg'} alt="notch" />
								<CardBody backGround="green_mirror">
									<Spacer height={3} />
									<Flexed direction="row" justify="center" align="center">
										<UniswapImage
											isSmallerIcon={false}
											isDarkTheme={isDarkTheme}
											src="/images/pancake_swap.png"
											onClick={() => {
												buySSST()
											}}
										/>
									</Flexed>
									<Spacer height={3} />
								</CardBody>
								<NotchImgBottom src={'/images/mini_green_notch.svg'} width="105%" alt="bottom-notch" />
							</Card>
						</Block>
						<Row>
							<Col lg={12}>
								<HeadingLightEffect isDarkTheme={isDarkTheme} />
								<Heading isDarkTheme={isDarkTheme}>To purchase Venture tokens, follow these steps:</Heading>
								<Spacer height={2} />
							</Col>
							<FristStep>
								<Text xsmall isDarkTheme={isDarkTheme}>
									1. Please copy this Venture token address:
								</Text>
								<Flexed direction="row" align="center">
									<Address margin="0rem 0.5rem 0rem 1.1rem" xsmall isDarkTheme={isDarkTheme}>
										{address}
									</Address>
									<CopyContent>
										<Text xsmall onClick={copy} bold pointer color={isDarkTheme ? 'aqua_blue' : 'blue'}>
											<BsFiles />
										</Text>
										{toolTipText && <Tooltip isDarkTheme={isDarkTheme}>Copied</Tooltip>}
									</CopyContent>
								</Flexed>
							</FristStep>
							<Step>
								<Flexed direction="row" align="center">
									<Text xsmall isDarkTheme={isDarkTheme}>
										2. Click
									</Text>
									<UniswapImage
										isSmallerIcon={true}
										isDarkTheme={isDarkTheme}
										src="/images/pancake_swap.png"
										onClick={() => {
											buySSST()
										}}
										alt="pancake_swap"
									/>
								</Flexed>
							</Step>
							<Col>
								{' '}
								<Spacer height={1} />
							</Col>
							<StepCard xs={12} sm={6} md={4} lg={4}>
								<InstructionsImages isDarkTheme={isDarkTheme} src={'/images/step_1_1.png'} alt="step-1" />
								<Spacer height={1} />
								<Text xsmall isDarkTheme={isDarkTheme}>
									3. Please select a currency.
								</Text>
							</StepCard>
							<StepCard xs={12} sm={6} md={4} lg={4}>
								<InstructionsImages isDarkTheme={isDarkTheme} src={'/images/step_1_2.png'} alt="step-2" />
								<Spacer height={1} />
								<Text xsmall isDarkTheme={isDarkTheme}>
									4. Enter the copied Venture token address in the search bar.
								</Text>
							</StepCard>
							<StepCard xs={12} sm={6} md={4} lg={4}>
								<InstructionsImages isDarkTheme={isDarkTheme} src={'/images/step_1_3.png'} alt="step-3" />
								<Spacer height={1} />
								<Text xsmall isDarkTheme={isDarkTheme}>
									5. Wait for the token details to be displayed. Once you see the token details, click on "Add", and then proceed to click on "Token Details".
								</Text>
							</StepCard>
							<StepCard xs={12} sm={6} md={4} lg={4}>
								<InstructionsImages isDarkTheme={isDarkTheme} src={'/images/step_1_4.png'} alt="step-4" />
								<Spacer height={1} />
								<Text xsmall isDarkTheme={isDarkTheme}>
									6. Enter the amount you wish to swap. Click on "Swap," and then confirm the swap. Your transaction is complete!
								</Text>
							</StepCard>
							<Col>
								<Spacer height={1} />
							</Col>
							<Step>
								<Text xsmall isDarkTheme={isDarkTheme}>
									7. Now, it's time to view the purchased Venture tokens in Metamask as your asset.
								</Text>
							</Step>
							<Col>
								<Spacer height={1} />
							</Col>
							<StepCard xs={12} sm={6} md={4} lg={4}>
								<InstructionsImages isDarkTheme={isDarkTheme} src={'/images/step_2_1.png'} />
								<Spacer height={1} />
								<Text xsmall isDarkTheme={isDarkTheme}>
									8. Open Metamask, click on "Assets," and then select "Import Tokens" located at the bottom.
								</Text>
							</StepCard>
							<StepCard xs={12} sm={6} md={4} lg={4}>
								<InstructionsImages isDarkTheme={isDarkTheme} src={'/images/step_2_2.png'} />
								<Spacer height={1} />
								<Text xsmall isDarkTheme={isDarkTheme}>
									9. Enter the copied Venture token address. Once the token details are displayed, click on "Add Custom Token," and then proceed to click on "Import Tokens."
								</Text>
							</StepCard>
							<StepCard xs={12} sm={6} md={4} lg={4}>
								<InstructionsImages isDarkTheme={isDarkTheme} src={'/images/step_2_3.png'} />
								<Spacer height={1} />
								<Text xsmall isDarkTheme={isDarkTheme}>
									10. Your purchased Venture tokens will now be displayed in your assets. Boom!
								</Text>
							</StepCard>
						</Row>
					</Form>
				</Col>
				<StatisticsSection xl={4} xxl={4}>
					<Form isDarkTheme={isDarkTheme}>
						<Row isDarkTheme={isDarkTheme} justifyContent="center">
							<CardMetrics>
								<NotchImg src={'/images/gray_notch.svg'} width="104%" alt="notch" />
								<MetricsCardBody backGround="gray_mirror" width="26rem">
									<Spacer height={4} />
									<Col lg={12}>
										<Heading color={isDarkTheme ? 'white' : 'black'}>Venture Metrics</Heading>
									</Col>
									<Column isDarkTheme={isDarkTheme}>
										<Text xsmall isDarkTheme={isDarkTheme}>
											Token Name
										</Text>
										<Text xsmall color={isDarkTheme ? 'white' : 'black'}>
											{tokenName}
										</Text>
									</Column>
									<Column isDarkTheme={isDarkTheme}>
										<Text xsmall isDarkTheme={isDarkTheme}>
											Token Symbol
										</Text>
										<Text xsmall color={isDarkTheme ? 'white' : 'black'}>
											{tokenSymbol}
										</Text>
									</Column>
									<Column isDarkTheme={isDarkTheme}>
										<Text xsmall isDarkTheme={isDarkTheme}>
											Decimals
										</Text>
										<Text xsmall color={isDarkTheme ? 'white' : 'black'}>
											{decimals}
										</Text>
									</Column>
									<Column isDarkTheme={isDarkTheme}>
										<Text xsmall isDarkTheme={isDarkTheme}>
											Total Supply
										</Text>
										<Text xsmall color={isDarkTheme ? 'white' : 'black'}>
											{totalSupply}
										</Text>
									</Column>
									<Spacer height={4} />
								</MetricsCardBody>
								<NotchImgBottom src={'/images/gray_notch.svg'} width="104%" alt="bottom-notch" />
							</CardMetrics>
						</Row>
					</Form>
					<Form isDarkTheme={isDarkTheme}>
						<Row justifyContent="center">
							<CardMetrics>
								<NotchImg src={'/images/gray_notch.svg'} width="104%" alt="notch" />
								<PieCardBody id="piechart" backGround="gray_mirror">
									<Spacer height={2} />
									<Chart width={'350px'} height={'320px'} chartType="PieChart" loader={<div>Loading Chart</div>} data={pieData} options={pieOptions} rootProps={{'data-testid': '3'}} />
								</PieCardBody>
								<NotchImgBottom src={'/images/gray_notch.svg'} width="104%" alt="bottom-notch" />
							</CardMetrics>
						</Row>
					</Form>
				</StatisticsSection>
			</Row>
		</Wrapper>
	)
}
const Wrapper = styled(Container)`
	position: relative;
	z-index: 2;
`

const Block = styled(Container)`
	display: block;
	text-align: center;
	max-width: 100%;
	padding-top: 1.2rem;
	padding-bottom: 1.5rem;
`
const Card = styled.div`
	display: inline-block;
	position: relative;
	margin: 0 1rem 1.8rem 1rem;
	${media.xs`
		margin: 0 0rem 1.8rem 0rem;
    `};
	@media screen and (min-width: 473px) and (max-width: 575px) {
		margin: 0 2rem 3.5rem 1rem !important;
	}
	@media screen and (min-width: 575px) and (max-width: 606px) {
		margin: 0 1rem 3.5rem 1rem !important;
	}
	${media.sm`
		margin: 0 1rem 1.8rem 1rem;
    `};
	${media.xl`
		margin: 0 0.5rem 1.8rem 0.5rem;
    `};
	${media.xxl`
	margin: 0 1rem 1.8rem 1rem;
    `};
`

const CardBody = styled.div`
	clip-path: polygon(0 0, 12% 0, 28% 7%, 71% 7%, 86% 0, 100% 0, 100% 100%, 88% 100%, 70% 93%, 29% 93%, 14% 100%, 0 100%);
	padding: 0rem 0.2rem;
	display: inline-block;
	vertical-align: top;
	text-align: left;
	overflow: hidden;
	position: relative;
	background: ${({backGround}) => palette[backGround]};
	border: none;
	font-size: 1rem;
	width: ${({width}) => (width ? width : '13rem')};
	min-height: 10rem;
	transition: all 0.4s ease-in-out;
	&:hover #video {
		display: block;
	}
	&:hover #image {
		display: none;
	}
`
const NotchImg = styled.img`
	position: absolute;
	top: -16px;
	width: ${({width}) => (width ? width : '106.2%')};
	z-index: 2;
	left: -7px;
`

const NotchImgBottom = styled.img`
	position: absolute;
	bottom: -16px;
	width: ${({width}) => (width ? width : '106.2%')};
	z-index: 2;
	left: -7px;
	transform: rotate(180deg);
`

const MetricsCardBody = styled(CardBody)`
	width: 18rem;
	${media.xl`
	width: 20rem;
    `};
	${media.xxl`
	width: 20rem;
    `};
`
const PieCardBody = styled(CardBody)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 18rem;
	${media.xl`
	width: 20rem;
    `};
	${media.xxl`
	width: 20rem;
    `};
`

const IconDisplay = styled.img`
	width: 13rem;
	z-index: 4;
	position: relative;
`

const CardMetrics = styled.div`
	display: inline-block;
	position: relative;
`

const LightEffect = styled.div`
	position: absolute;
	background: ${palette.blue_gradient_shadow};
	width: 4rem;
	height: 7rem;
	filter: blur(4rem);
	left: 0;
	right: 0;
	margin: auto;
`

const HeadingLightEffect = styled(LightEffect)`
	width: 17rem;
	height: 3rem;
	filter: ${({isDarkTheme}) => (isDarkTheme ? `blur(5.5rem)` : `blur(16rem)`)};
`

const Form = styled(Container)`
	padding: ${({padding}) => (padding ? padding : '2rem')};
`

const Heading = styled.h2`
	line-height: 1.85rem;
	font-weight: ${({bold}) => (bold ? 'bold' : 'normal')};
	color: ${({isDarkTheme, color}) => (color ? palette[color] : isDarkTheme ? palette.aqua_blue : palette.blue)};
	margin: 0;
	text-align: center;
	font-size: 1rem;
	font-weight: bold;
`

const Image = styled.div`
	position: absolute;
	background: ${({src, isDarkTheme}) => (src ? `url(${src}) no-repeat` : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	background-size: contain;
	background-position: center;
	width: 2.3rem;
	height: 2.3rem;
`

const FristStep = styled(Col)`
	display: flex;
	${media.xs`
	flex-direction: column;
	text-align:center;
	& > div{
		justify-content:center;
	}
    `};
	${media.sm`
	flex-direction: column;
    `};
	${media.md`
	flex-direction: row;
    `};
`

const Step = styled(Col)`
	display: flex;
	${media.xs`
	flex-direction: column;
	text-align:center;
	& > div{
		justify-content:center;
	}
	margin-top:1rem;
	`};
`

const StepCard = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: ${({justifyContent}) => `${justifyContent}`};
	margin-bottom: 1rem;
	${media.xs`
	justify-content: center;
	align-items: center;
	text-align:center;
    `};
	${media.sm`
	justify-content: ${({justifyContent}) => `${justifyContent}`};
	align-items: left;
    `};
`

const spinnerAnimation = keyframes`
	to {
		transform: scale(1.12);
	}
`

const UniswapImage = styled.img`
	width: ${({isSmallerIcon}) => (isSmallerIcon ? '3rem' : '9rem')};
	cursor: pointer;
	filter: ${({isDarkTheme}) => (isDarkTheme ? '' : 'invert(1)')};
	animation: ${spinnerAnimation} 1s infinite alternate;
`

const Address = styled(Text)`
	${media.xs`
	text-overflow: ellipsis;
	overflow: hidden;
	width: 12.5rem;
	height: 1.2em;
	text-align:center;
    `};
`

const Column = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	border-bottom: 0.063rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_dark}` : `${palette.gray}`)};
`

const InstructionsImages = styled.img`
	width: 14rem;
	height: 22rem;
	border-radius: 1rem;
	border: 0.063rem solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
`
const CopyContent = styled.div`
	position: relative;
	cursor: pointer;
`

const Tooltip = styled.div`
	font-size: 0.8rem;
	position: absolute;
	right: -3.5rem;
	bottom: 0rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
`

const StatisticsSection = styled(Col)`
	${media.lg`
display:flex;
    `};
	${media.xl`
display:block;
    `};
	${media.xxl`
	display:block;
`};
`

export default Tokens
