import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../assets/common'
import CardButton from './CardButton'
import { useSelector } from 'react-redux'
import { Text, Spacer, Flexed } from '../styles/shared'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const MobileVersion = ({ goBack, gameDetails }) => {
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	let navigate = useNavigate()

	const goToLogin = async () => {
		//todo..if login then show tick mark otherwise redictt it to login
	}

	const buySSST = async () => {
		navigate('/mobile_tokens')
	}

	const downloadAPK = () => {
		alert('')
	}

	return (
		<Wrapper>
			<CustomRow>
				<Col lg={12}>
					<Form isDarkTheme={isDarkTheme}>
						<Row>
							<Col>
								<Header flexWrap='wrap'>
									<CardButton isDarkTheme={isDarkTheme} label={'Back'} ifClicked={goBack} />
									<Heading isDarkTheme={isDarkTheme}>To play {gameDetails?.gameName} on mobile:</Heading>
									<InvisibleSpace />
								</Header>
							</Col>
						</Row>
						<Spacer height={3} />
						<Row justifyContent="center">
							<Card
								xs={12}
								sm={6}
								md={4}
								lg={4}
								xl={3}
								onClick={() => {
									goToLogin()
								}}>
								<Media>
									<Image src="/images/create_account.png" />
								</Media>
								<Text bold small isCentered isDarkTheme={isDarkTheme}>
									1. Creating an account on the web via Metamask (Binance Test Network)
								</Text>
							</Card>
							<Card
								xs={12}
								sm={6}
								md={4}
								lg={4}
								xl={3}
								onClick={() => {
									buySSST()
								}}>
								<Media>
									<Image src="/images/m_ssst.png" />
								</Media>
								<Text bold small isCentered isDarkTheme={isDarkTheme}>
									2. Acquire Mobile Venture Tokens (M-Venture)
								</Text>
							</Card>
							<DownloadWrapper xs={12} sm={6} md={4} lg={4} xl={3}>
								<Media>
									<a href={`${gameDetails.mobileUrl}`} class={isDarkTheme ? 'animated-button-dark' : 'animated-button-light'}>
										<span></span>
										<span></span>
										<span></span>
										<span></span>
										Download APK
									</a>
								</Media>
								<Text bold small isCentered isDarkTheme={isDarkTheme}>
									3. Download, install, and play. It's secure and trustworthy, with no security risks. Additionally, no ads are integrated
								</Text>
							</DownloadWrapper>
						</Row>
						<Row>
							<Col>
								<Heading isDarkTheme={isDarkTheme}>Gameplay Instructions:</Heading>
							</Col>
						</Row>
						<Spacer height={2} />
						<Row justifyContent="center">
							<Col md={10}>
								<Flexed direction="row">
									<Point isDarkTheme={isDarkTheme}>1.</Point>
									<Text small left isDarkTheme={isDarkTheme}>
										Connect via Metamask from the web. Your secret ID and password for mobile games will be automatically generated.
									</Text>
								</Flexed>
								<Flexed direction="row">
									<Point isDarkTheme={isDarkTheme}>2.</Point>
									<Text small left isDarkTheme={isDarkTheme}>
										You can find your secret ID and password in my profile. (Web only)
									</Text>
								</Flexed>
								<Flexed direction="row">
									<Point isDarkTheme={isDarkTheme}>3.</Point>
									<Text small left isDarkTheme={isDarkTheme}>
										Log in to the mobile game with your secret ID and password. Your web game state will be synchronized automatically, or vice versa.
									</Text>
								</Flexed>
								<Flexed direction="row">
									<Point isDarkTheme={isDarkTheme}>4.</Point>
									<Text small left isDarkTheme={isDarkTheme}>
										To enjoy all game features on mobile, you need {' '}
										<Links isDarkTheme={isDarkTheme} to="/mobile_tokens">
											M-Venture tokens{' '}
										</Links>{' '}
									</Text>
								</Flexed>
								<Flexed direction="row">
									<Point isDarkTheme={isDarkTheme}>5.</Point>
									<Text small left isDarkTheme={isDarkTheme}>
										<Links isDarkTheme={isDarkTheme} to="/mobile_tokens">
											Convert
										</Links>{' '}
										Venture tokens to{' '}
										<Links isDarkTheme={isDarkTheme} to="/mobile_tokens">
											M-Venture tokens
										</Links>
									</Text>
								</Flexed>
								<Flexed direction="row">
									<Point isDarkTheme={isDarkTheme}>6.</Point>
									<Text small left isDarkTheme={isDarkTheme}>
										Currently, you cannot convert M-Venture tokens back to Venture tokens. (This functionality will be available soon.)
									</Text>
								</Flexed>
								<Flexed direction="row">
									<Point isDarkTheme={isDarkTheme}>7.</Point>
									<Text small left isDarkTheme={isDarkTheme}>
										You can update your secret ID and password from my profile. (Web only)
									</Text>
								</Flexed>
								<Spacer height={2} />
							</Col>
						</Row>
					</Form>
				</Col>
			</CustomRow>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	position: relative;
	z-index: 2;
	padding-top: 1rem;
	@media screen and (min-width: 415px) and (max-width: 9999px) {
		display: flex;
		justify-content: center;
	}
`

const CustomRow = styled(Row)`
	justify-content: center;
	${media.xl`
        width: 95%;
    `};
	${media.xxl`
        width: 90%;
    `};
`

const Heading = styled.h2`
	line-height: 1.85rem;
	font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.aqua_blue : palette.blue)};
	margin: 0;
	text-align: center;
	font-size: 1.5rem;
	font-weight: bold;
`

const Form = styled(Container)`
	padding: 2rem;
`

const Image = styled.div`
	background: ${({ src, isDarkTheme }) => (src ? `url(${src}) no-repeat` : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	background-size: contain;
	background-position: center;
	width: 4.75rem;
	height: 4.75rem;
`
const Card = styled(Col)`
	cursor: pointer;
	margin-bottom: 2rem;
`
const DownloadWrapper = styled(Col)`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-bottom: 2rem;
`

const Header = styled(Flexed)`
	flex-direction: column;
	gap:2rem;
	${media.lg`
		flex-direction:row;
		align-item:center;
		gap:1rem;
		justify-content: space-between;
	`};
`

const Media = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 5rem;
	margin-bottom: 1rem;
`

const Links = styled(Link)`
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	&:hover {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	}
`

const Point = styled.div`
	display: inline-block;
	margin-right: 0.5rem;
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	font-weight: bold;
`

const InvisibleSpace = styled.div`
	width: 129.27px;
	display:none;
	${media.lg`
		display:block;
	`};
`

export default MobileVersion
