import * as types from '../actions/types'

const initialState = {
	isConnected: false,
	shouldConnect: false,
	isDarkTheme: true,
	user: null,
	tokenContract: null,
	signer: null,
	isGameInProgress: false,
	isExitGameModalOpen: false,
	urlAfterGameExit: null
}

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case types.TRIGGER_CONNECT:
			return {
				...state,
				shouldConnect: true
			}
		case types.CONNECTED_SUCCESS:
			return {
				...state,
				...action.value,
				shouldConnect: false,
				address: action.value.address
			}
		case types.DISCONNECTED_SUCCESS:
			return {
				...initialState
			}
		case types.SAVE_USER:
			return {
				...state,
				user: action.value
			}
		case types.SWITCH_THEME: {
			return {
				...state,
				isDarkTheme: action.value
			}
		}
		case types.GAME_STATUS: {
			return {
				...state,
				isGameInProgress: action.value
			}
		}
		case types.GAME_EXIT_MODAL: {
			return {
				...state,
				isExitGameModalOpen: action.value
			}
		}
		case types.OPEN_URL_AFTER_EXIT: {
			return {
				...state,
				urlAfterGameExit: action.value
			}
		}

		default:
			return state
	}
}
