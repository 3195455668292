import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Container, media} from 'styled-bootstrap-grid'
import {palette} from '../assets/common'
import CardButton from '../components/CardButton'
import {useSelector, useDispatch} from 'react-redux'
import {Flexed, Text, Spacer} from '../styles/shared'
import {AiTwotoneCheckCircle} from 'react-icons/ai'
import {setGameStatus} from '../actions/authActions'
import GamePage from '../components/GamePage'
import InfoContent from '../components/InfoContent'
import WebVersion from '../components/WebVersion'
import MobileVersion from '../components/MobileVersion'
import Loader from '../components/Loader'

const featureLabels = {new: 'new', hot: 'hot', revised: 'revised'}

const Home = () => {
	const dispatch = useDispatch()
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	const [isHome, setIsHome] = useState(true)
	const [isWeb, setIsWeb] = useState(false)
	const [isMobile, setIsMobile] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [size, setSize] = useState({
		x: window.innerWidth,
		y: window.innerHeight
	})

	const updateSize = () =>
		setSize({
			x: window.innerWidth,
			y: window.innerHeight
		})

	useEffect(() => (window.onresize = updateSize), [])

	useEffect(() => {
		setTimeout(() => setIsLoading(false), 1000)
	}, [])

	const [gamesDetails, setGamesDetails] = useState([
		{
			id: 1,
			notch: '/images/blue_notch.svg',
			backGround: 'blue_mirror',
			backShadow: 'aqua_blue',
			buttonType: 'primary',
			gameName: 'Call of Ventures',
			image: '/images/fps.png',
			mobileUrl: 'https://www.dropbox.com/scl/fi/54wdhdkcqwqt6u94jtjdp/FPS_11_June.apk?rlkey=vcg7xdlpkcj8nq5ad1x8bh81e&dl=1',
			webUrl: 'https://fps.playriseventures.com/',
			isHover: false,
			video: '/media/sample-video2.mp4',
			featureLabel: featureLabels?.new,
			description: ['2500 Mints', 'Engage in pool games to earn real money in either BNB or ETH.', `Achieve victory over your opponents in challenges, and you will witness a 70% increase in your venture tokens.`, 'Engage in competition with the venture family and maximize your venture tokens.'],
			competition: 'Earn 70% of total tokens',
			challenges: 'Earn 80% of total tokens',
			pool: 'Earn 80% of total payment',
			players: '1',
			available_mints: '2500',
			mints: '1'
		},
		{
			id: 2,
			notch: '/images/pink_notch.svg',
			backGround: 'pink_mirror',
			backShadow: 'pink',
			buttonType: 'secondary',
			gameName: 'Spider Warrior',
			image: '/images/spider.png',
			mobileUrl: 'https://www.dropbox.com/scl/fi/re67bn8gzmycwa3upspa3/Spider_11_June.apk?rlkey=djqkuf5rjb9s61t7767mgcd0m&dl=1',
			webUrl: 'https://spider.playriseventures.com/',
			video: '/media/sample-video2.mp4',
			isHover: false,
			featureLabel: featureLabels?.new,
			description: ['1600 Mints', 'Engage in pool games to earn real money in either BNB or ETH.', `Achieve victory over your opponents in challenges, and you will witness a 70% increase in your venture tokens.`, 'Engage in competition with the venture family and maximize your venture tokens.'],
			competition: 'Earn 70% of total tokens',
			challenges: 'Earn 80% of total tokens',
			pool: 'Earn 80% of total payment',
			players: '1',
			available_mints: '1600',
			mints: '1'
		},
		{
			id: 3,
			notch: '/images/orange_notch.svg',
			backGround: 'orange_mirror',
			backShadow: 'orange',
			buttonType: 'orange',
			gameName: 'Monsters The Champ',
			image: '/images/monster.png',
			mobileUrl: 'https://www.dropbox.com/scl/fi/6anh9pm4iq5wf7i4sqx0k/Champ_11_June.apk?rlkey=m47xxuhgfyed7j0ng1rxwun4c&dl=1',
			webUrl: 'https://champ.playriseventures.com/',
			video: '/media/sample-video2.mp4',
			isHover: false,
			featureLabel: featureLabels?.new,
			description: ['54,000 Mints', 'Engage in pool games to earn real money in either BNB or ETH.', `Achieve victory over your opponents in challenges, and you will witness a 70% increase in your venture tokens.`, 'Engage in competition with the venture family and maximize your venture tokens.'],
			competition: 'Earn 70% of total tokens',
			challenges: 'Earn 80% of total tokens',
			pool: 'Earn 80% of total payment',
			players: '1',
			available_mints: '54,00',
			mints: '1'
		},
		{
			id: 4,
			notch: '/images/gray_notch.svg',
			backGround: 'gray_mirror',
			backShadow: 'white',
			buttonType: 'gray',
			gameName: 'Invisible Ventures',
			image: '/images/card.png',
			isHover: false,
			video: '/media/sample-video2.mp4',
			mobileUrl: 'https://www.dropbox.com/scl/fi/niook4uxgnl68lfl07r2t/Card_11_June.apk?rlkey=kjppke1my5omlq2yebnnqa4kg&dl=1',
			webUrl: 'https://card.playriseventures.com/',
			featureLabel: featureLabels?.new,
			description: ['240 Mints', 'Engage in pool games to earn real money in either BNB or ETH.', `Achieve victory over your opponents in challenges, and you will witness a 70% increase in your venture tokens.`, 'Engage in competition with the venture family and maximize your venture tokens.'],
			competition: 'Earn 70% of total tokens',
			challenges: 'Earn 80% of total tokens',
			pool: 'Earn 80% of total payment',
			players: '1',
			available_mints: '240',
			mints: '1'
		},
		{
			id: 5,
			notch: '/images/blue_notch.svg',
			backGround: 'blue_mirror',
			backShadow: 'aqua_blue',
			buttonType: 'primary',
			gameName: 'Venture Horse',
			image: '/images/horse.png',
			isHover: false,
			video: '/media/sample-video2.mp4',
			mobileUrl: 'https://www.dropbox.com/scl/fi/q5ysbza0v5khpooqv73qb/Horse_11_June.apk?rlkey=ih7f17xd1sxayv7gybgw9efr7&dl=1',
			webUrl: 'https://horse.playriseventures.com/',
			featureLabel: featureLabels?.new,
			description: ['960 Mints', 'Engage in pool games to earn real money in either BNB or ETH.', `Achieve victory over your opponents in challenges, and you will witness a 70% increase in your venture tokens.`, 'Engage in competition with the venture family and maximize your venture tokens.'],
			competition: 'Earn 70% of total tokens',
			challenges: 'Earn 80% of total tokens',
			pool: 'Earn 80% of total payment',
			players: '1',
			available_mints: '960',
			mints: '1'
		},
		{
			id: 6,
			notch: '/images/pink_notch.svg',
			backGround: 'pink_mirror',
			backShadow: 'pink',
			buttonType: 'secondary',
			gameName: 'Venture Roulette',
			image: '/images/spider.png',
			mobileUrl: 'https://www.dropbox.com/scl/fi/w0g894t7saa7as4t4i8s3/Roulette_11_June.apk?rlkey=m2mvg5lk7sxi0mozns4k5lma1&dl=1',
			webUrl: 'https://roulette.playriseventures.com/',
			video: '/media/sample-video2.mp4',
			isHover: false,
			featureLabel: featureLabels?.new,
			description: ['0 Mints', 'Engage in pool games to earn real money in either BNB or ETH.', `Achieve victory over your opponents in challenges, and you will witness a 70% increase in your venture tokens.`, 'Engage in competition with the venture family and maximize your venture tokens.'],
			competition: 'Earn 70% of total tokens',
			challenges: 'Earn 80% of total tokens',
			pool: 'Earn 80% of total payment',
			players: '1',
			available_mints: '0',
			mints: '0'
		},
		{
			id: 7,
			notch: '/images/orange_notch.svg',
			backGround: 'orange_mirror',
			backShadow: 'orange',
			buttonType: 'orange',
			gameName: 'Venture Casino',
			image: '/images/monster.png',
			mobileUrl: '',
			webUrl: 'https://casino.playriseventures.com/',
			video: '/media/sample-video2.mp4',
			isHover: false,
			featureLabel: featureLabels?.new,
			description: ['0 Mints', 'Engage in pool games to earn real money in either BNB or ETH.', `Achieve victory over your opponents in challenges, and you will witness a 70% increase in your venture tokens.`, 'Engage in competition with the venture family and maximize your venture tokens.'],
			competition: 'Earn 70% of total tokens',
			challenges: 'Earn 80% of total tokens',
			pool: 'Earn 80% of total payment',
			players: '1',
			available_mints: '0',
			mints: '0'
		},
		{
			id: 8,
			notch: '/images/gray_notch.svg',
			backGround: 'gray_mirror',
			backShadow: 'white',
			buttonType: 'gray',
			gameName: 'Venture Slicer',
			image: '/images/card.png',
			isHover: false,
			video: '/media/sample-video2.mp4',
			mobileUrl: 'https://www.dropbox.com/scl/fi/t8trq3sxe48vw6a6akix9/Slicer_11_June.apk?rlkey=o1wdwbue4hcdms10rk1k55x01&dl=1',
			webUrl: 'https://swipecoins.playriseventures.com/',
			featureLabel: featureLabels?.new,
			description: ['192 Mints', 'Engage in pool games to earn real money in either BNB or ETH.', `Achieve victory over your opponents in challenges, and you will witness a 70% increase in your venture tokens.`, 'Engage in competition with the venture family and maximize your venture tokens.'],
			competition: 'Earn 70% of total tokens',
			challenges: 'Earn 80% of total tokens',
			pool: 'Earn 80% of total payment',
			players: '1',
			available_mints: '192',
			mints: '1'
		},
		{
			id: 9,
			notch: '/images/blue_notch.svg',
			backGround: 'blue_mirror',
			backShadow: 'aqua_blue',
			buttonType: 'primary',
			gameName: 'Venture Ludo',
			image: '/images/fps.png',
			mobileUrl: 'https://www.dropbox.com/scl/fi/etd6v3yukoh90d67eaebg/Ludo_11_June.apk?rlkey=9w2caii6gmsq8fa99lu3lnpc6&dl=1',
			webUrl: 'https://ludo.playriseventures.com/',
			isHover: false,
			video: '/media/sample-video2.mp4',
			featureLabel: featureLabels?.new,
			description: ['0 Mints', 'Engage in pool games to earn real money in either BNB or ETH.', `Achieve victory over your opponents in challenges, and you will witness a 70% increase in your venture tokens.`, 'Engage in competition with the venture family and maximize your venture tokens.'],
			competition: 'Earn 70% of total tokens',
			challenges: 'Earn 80% of total tokens',
			pool: 'Earn 80% of total payment',
			players: '1',
			available_mints: '0',
			mints: '0'
		},
		{
			id: 10,
			notch: '/images/pink_notch.svg',
			backGround: 'pink_mirror',
			backShadow: 'pink',
			buttonType: 'secondary',
			gameName: 'Venture Ludo-Snake',
			image: '/images/spider.png',
			mobileUrl: 'https://www.dropbox.com/scl/fi/9bxyn0fh7ojvqn9vojtg4/Snake_11_June.apk?rlkey=3hk60vvbrjdv85e2282g8w767&dl=1',
			webUrl: 'https://ludosnake.playriseventures.com/',
			video: '/media/sample-video2.mp4',
			isHover: false,
			featureLabel: featureLabels?.new,
			description: ['0 Mints', 'Engage in pool games to earn real money in either BNB or ETH.', `Achieve victory over your opponents in challenges, and you will witness a 70% increase in your venture tokens.`, 'Engage in competition with the venture family and maximize your venture tokens.'],
			competition: 'Earn 70% of total tokens',
			challenges: 'Earn 80% of total tokens',
			pool: 'Earn 80% of total payment',
			players: '1',
			available_mints: '0',
			mints: '0'
		},
		{
			id: 11,
			notch: '/images/orange_notch.svg',
			backGround: 'orange_mirror',
			backShadow: 'orange',
			buttonType: 'orange',
			gameName: 'Venture Mario',
			image: '/images/monster.png',
			mobileUrl: 'https://www.dropbox.com/scl/fi/cqusky06ofquowrso65m7/Mario_11_June.apk?rlkey=p7cuyva7vay4ie2gwxld0g2kv&dl=1',
			webUrl: 'https://vmario.playriseventures.com/',
			video: '/media/sample-video2.mp4',
			isHover: false,
			featureLabel: featureLabels?.new,
			description: ['192 Mints', 'Engage in pool games to earn real money in either BNB or ETH.', `Achieve victory over your opponents in challenges, and you will witness a 70% increase in your venture tokens.`, 'Engage in competition with the venture family and maximize your venture tokens.'],
			competition: 'Earn 70% of total tokens',
			challenges: 'Earn 80% of total tokens',
			pool: 'Earn 80% of total payment',
			players: '1',
			available_mints: '192',
			mints: '1'
		},
		{
			id: 12,
			notch: '/images/gray_notch.svg',
			backGround: 'gray_mirror',
			backShadow: 'white',
			buttonType: 'gray',
			gameName: 'Venture Ball',
			image: '/images/card.png',
			isHover: false,
			video: '/media/sample-video2.mp4',
			mobileUrl: 'https://www.dropbox.com/scl/fi/armo4ny8id9su3pnfd6p6/Ball_11_June.apk?rlkey=bfprbi8xyp41r7p0m7o7vqmdb&dl=1',
			webUrl: 'https://vball.playriseventures.com/',
			featureLabel: featureLabels?.new,
			description: ['192 Mints', 'Engage in pool games to earn real money in either BNB or ETH.', `Achieve victory over your opponents in challenges, and you will witness a 70% increase in your venture tokens.`, 'Engage in competition with the venture family and maximize your venture tokens.'],
			competition: 'Earn 70% of total tokens',
			challenges: 'Earn 80% of total tokens',
			pool: 'Earn 80% of total payment',
			players: '1',
			available_mints: '192',
			mints: '1'
		},
		{
			id: 13,
			notch: '/images/blue_notch.svg',
			backGround: 'blue_mirror',
			backShadow: 'aqua_blue',
			buttonType: 'primary',
			gameName: 'Venture Tower Jump',
			image: '/images/fps.png',
			mobileUrl: 'https://www.dropbox.com/scl/fi/2b164c9ehu70v176rzar6/Jump_11_June.apk?rlkey=qb1xwbc7l9j4q2c6l21datws0&dl=1',
			webUrl: 'https://tower.playriseventures.com/',
			isHover: false,
			video: '/media/sample-video2.mp4',
			featureLabel: featureLabels?.new,
			description: ['48 Mints', 'Engage in pool games to earn real money in either BNB or ETH.', `Achieve victory over your opponents in challenges, and you will witness a 70% increase in your venture tokens.`, 'Engage in competition with the venture family and maximize your venture tokens.'],
			competition: 'Earn 70% of total tokens',
			challenges: 'Earn 80% of total tokens',
			pool: 'Earn 80% of total payment',
			players: '1',
			available_mints: '48',
			mints: '1'
		}
	])

	return (
		<>
			{isLoading && <Loader />}
			{isHome && (
				<>
					<Spacer height="2" />
					<Wrapper>
						{gamesDetails.map((game, index) => {
							return (
								<Card key={index + 1}>
									<NotchImg className="notch_img" src={game.notch} alt="notch" />
									<CardBody isDarkTheme={isDarkTheme} backGround={game.backGround} className="cardWrapper">
										<FirstSpan color={game?.buttonType}></FirstSpan>
										<SecondSpan color={game?.buttonType}></SecondSpan>
										<ThirdSpan color={game?.buttonType}></ThirdSpan>
										<FourthSpan color={game?.buttonType}></FourthSpan>
										<Image id="image" src={game?.image} alt="cover" />
										{size.x > 768 ? (
											<Video id="video" autoPlay={size.x > 768 ? true : false} loop muted>
												<source src={game?.video} type="video/mp4" />
											</Video>
										) : null}
										<Tag id="labelTag" backGround={game?.featureLabel}>
											{game?.featureLabel}
										</Tag>
										<Content>
											{/* <CardHeading className="shimmer" isDarkTheme={isDarkTheme}>{game.gameName}</CardHeading> */}
											{/* <h1 class="shimmer">{game.gameName}</h1> */}
											<Spacer height={1} />
											<h1 className="froz" data-heading={game.gameName}>
												{game.gameName}
											</h1>
											{/* <Spacer height={0.3} /> */}
											{/* <Text lineHeight="none" xsmall isDarkTheme={isDarkTheme} color={isDarkTheme ? 'description_Text' : 'off_dark'} style={{ whiteSpace: 'pre-line' }}>
												{game.description.map((val, index) => {
													return (
														<Flexed key={index + 'i'} direction="row">
															<Point isDarkTheme={isDarkTheme}>
																<AiTwotoneCheckCircle />
															</Point>
															<div>{val}</div>
														</Flexed>
													)
												})}
											</Text>
											<Spacer height={1} /> */}
											{/* <Flexed direction="row" justify="space-between">
												<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
													Total Downloads
												</Text>
												<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
													{game?.downloads}
												</Text>
											</Flexed> */}

											<Flexed direction="row" justify="space-between">
												<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
													Venture Family
												</Text>
												<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
													{game?.players}
												</Text>
											</Flexed>
											<Flexed direction="row" justify="space-between">
												<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
													Total Mints
												</Text>
												<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
													{game?.available_mints}
												</Text>
											</Flexed>
											<Flexed direction="row" justify="space-between">
												<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
													Available Mints
												</Text>
												<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
													{game?.mints}
												</Text>
											</Flexed>
											<Flexed direction="row" justify="space-between">
												<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
													Competition
												</Text>
												<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
													{game?.competition}
												</Text>
											</Flexed>
											<Flexed direction="row" justify="space-between">
												<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
													Challenge
												</Text>
												<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
													{game?.challenges}
												</Text>
											</Flexed>
											<Flexed direction="row" justify="space-between">
												<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
													P2E
												</Text>
												<Text lineHeight="none" xsmall isDarkTheme={isDarkTheme}>
													{game?.pool}
												</Text>
											</Flexed>

											<Spacer height={1} />
											<CardFooter id="footer">
												<Play>
													<CardButton
														type={game?.buttonType}
														isDarkTheme={isDarkTheme}
														label={'Play Now'}
														ifClicked={() => {
															setIsHome(false)
															setIsWeb(game)
															dispatch(setGameStatus(true))
														}}
													/>
												</Play>
												<CardButton
													type={game?.buttonType}
													isDarkTheme={isDarkTheme}
													label={'Play on Mobile'}
													ifClicked={() => {
														setIsHome(false)
														setIsMobile(game)
													}}
												/>
											</CardFooter>
										</Content>
									</CardBody>
									<NotchImgBottom className="notch_img" src={game.notch} alt="notch" />
								</Card>
							)
						})}
					</Wrapper>
					<InfoContent />
				</>
			)}

			{isWeb && (
				<WebVersion
					goBack={() => {
						setIsHome(true)
						setIsWeb(false)
					}}
					gameDetails={isWeb}
				/>
			)}
			{isMobile && (
				<MobileVersion
					goBack={() => {
						setIsHome(true)
						setIsMobile(false)
					}}
					gameDetails={isMobile}
				/>
			)}
		</>
	)
}

const Wrapper = styled(Container)`
	display: block;
	text-align: center;
	max-width: 100%;
	padding-top: 1.2rem;
	padding-bottom: 2rem;
`
const Card = styled.div`
	display: inline-block;
	position: relative;
	z-index: 2;
	margin: 0 2rem 1.8rem 2rem;
	${media.xs`
		margin: 0 0rem 1.8rem 0rem;
    `};
	${media.sm`
		margin: 0 2rem 1.8rem 2rem;
    `};
	transition: all 0.4s ease-in-out;
	&:hover {
		transform: scale(1.03);
	}
	&:hover .notch_img {
		// -webkit-filter: drop-shadow(0px 0px 0px rgba(255,255,255,0.80));
	}
	@media screen and (min-width: 849px) and (max-width: 991px) {
		margin: 0 1rem 1.8rem 1rem;
	}
	@media screen and (min-width: 1278px) and (max-width: 1472px) {
		margin: 0 1rem 1.8rem 1rem;
	}
`

const CardFooter = styled.div`
	display: flex;
	justify-content: center;
	gap: 1rem;
	margin-bottom: 3rem;
	@media screen and (min-width: 0px) and (max-width: 369px) {
		flex-direction: column;
		align-items: center;
		margin-bottom: 2rem;
	}
`
const NotchImg = styled.img`
	position: absolute;
	top: -15px;
	width: 104.5%;
	left: -9px;
	// width:107.5%;
	// left: -15px;
	z-index: 2;
`

const NotchImgBottom = styled.img`
	position: absolute;
	bottom: -16px;
	width: 104.5%;
	left: -11px;
	// width:107.5%;
	// left: -15px;
	z-index: 2;

	transform: rotate(180deg);
`

const CardBody = styled.div`
	clip-path: polygon(0 0, 12% 0, 27.5% 6.5%, 72% 6.5%, 86% 0, 100% 0, 100% 100%, 88% 100%, 70% 93.5%, 29% 93.5%, 14% 100%, 0 100%);
	display: inline-block;
	vertical-align: top;
	text-align: left;
	overflow: hidden;
	position: relative;
	background: ${({backGround}) => palette[backGround]};
	border: none;
	font-size: 1rem;
	width: 26rem;
	min-height: 10rem;
	transition: all 0.4s ease-in-out;
	&:hover #video {
		display: block;
		${media.xs`
		display: none;
		`}
		${media.sm`
		display: none;
		`}
		${media.md`
		display: block;
		`}
	}
	&:hover #image {
		display: none;
		${media.xs`
		display: block;
		`}
		${media.sm`
		display: block;
		`}
		${media.md`
		display: none;
		`}
	}

	&:hover .shimmer {
		-webkit-animation-duration: 2.5s !important;
		-moz-animation-duration: 2.5s !important;
		animation-duration: 2.5s !important;
	}

	@media screen and (min-width: 0px) and (max-width: 369px) {
		width: 19rem;
		clip-path: polygon(0 0, 15% 0, 28% 4%, 70% 4%, 83% 0, 100% 0, 100% 100%, 84% 100%, 70% 96%, 29% 96%, 15% 100%, 0 100%);
	}
	@media screen and (min-width: 370px) and (max-width: 399px) {
		width: 21.5rem;
		clip-path: polygon(0 0, 14% 0, 27.5% 5%, 70% 5%, 85% 0, 100% 0, 100% 100%, 86% 100%, 70% 95%, 29% 95%, 15% 100%, 0 100%);
	}
	@media screen and (min-width: 400px) and (max-width: 450px) {
		width: 23rem;
		clip-path: polygon(0 0, 12% 0, 28.5% 5.5%, 70% 5.5%, 86% 0, 100% 0, 100% 100%, 86% 100%, 70% 95%, 29% 95%, 15% 100%, 0 100%);
	}
	@media screen and (min-width: 1278px) and (max-width: 1472px) {
		width: 23.5rem;
		clip-path: polygon(0 0, 12% 0, 28.5% 5.5%, 70% 5.5%, 86% 0, 100% 0, 100% 100%, 86% 100%, 70% 95%, 29% 95%, 15% 100%, 0 100%);
	}
	@media screen and (min-width: 849px) and (max-width: 991px) {
		width: 23.5rem;
		clip-path: polygon(0 0, 12% 0, 28.5% 5.5%, 70% 5.5%, 86% 0, 100% 0, 100% 100%, 86% 100%, 70% 95%, 29% 95%, 15% 100%, 0 100%);
	}
`

const Image = styled.img`
	width: 100%;
	object-fit: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 14rem;
	position: relative;
	z-index: 5;
`

const Point = styled.div`
	display: flex;
	margin-right: 0.5rem;
	// align-items: center;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.description_Text}` : `${palette.description_Text}`)};
	font-size: 14px;
	font-weight: bold;
	padding-top: 0.6rem;
	& svg {
		height: 0.15rem;
	}
`

const Video = styled.video`
	width: calc(100% - 6px);
	margin: 0px 2px;
	display: none;
	height: 14rem;
	object-fit: cover;
	position: relative;
	z-index: 5;
`

const Content = styled.div`
	padding: 1rem;
	margin-bottom: 1rem;
	width: 100%;
`

const CardHeading = styled.div`
	font-weight: bold;
	letter-spacing: 0.1em;
	font-size: 1rem;
	font-family: 'bicubik';
	z-index: 7;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.off_white}` : `${palette.off_dark}`)};
`

const Tag = styled.div`
	position: absolute;
	font-family: 'bicubik';
	letter-spacing: 0.1em;
	font-size: 0.8rem;
	font-weight: bold;
	padding: 0.4rem 0.5rem;
	background-color: ${({backGround}) => (backGround === 'hot' ? `${palette.hot}` : backGround === 'new' ? `${palette.aqua_blue}` : '#808080')};
	text-transform: capitalize;
	top: 2rem;
	left: 0.5rem;
	z-index: 5;
`

const Play = styled.div`
	${media.xs`display:none;`}
	${media.sm`display:none;`}
	${media.md`display:block;`}
`
// const FirstSpan = styled.span`
// 	background: linear-gradient(to right, transparent,${({color}) => (handleBackgroundType(color))};
// 	// background: linear-gradient(to right, transparent, #4caf50);
// `

const handleBackgroundType = (type) => {
	switch (type) {
		case 'success':
			return palette.green
		case 'primary':
			return palette.aqua_blue_bright
		case 'secondary':
			return palette.pink
		case 'orange':
			return palette.orange
		case 'gray':
			return palette.gray
		case 'red':
			return palette.red
		case 'yellow':
			return palette.yellow
		default:
			return palette.aqua_blue_bright
	}
}

const FirstSpan = styled.span`
	background: linear-gradient(to right, transparent, ${({color}) => handleBackgroundType(color)});
	// background: linear-gradient(to right, transparent, #4caf50);
`
const SecondSpan = styled.span`
	background: linear-gradient(to right, transparent, ${({color}) => handleBackgroundType(color)});
	// background: linear-gradient(to bottom, transparent,  #4caf50);
`
const ThirdSpan = styled.span`
	background: linear-gradient(to right, transparent, ${({color}) => handleBackgroundType(color)});
	// background: linear-gradient(to left, transparent,  #4caf50);
`
const FourthSpan = styled.span`
	background: linear-gradient(to right, transparent, ${({color}) => handleBackgroundType(color)});
	// background: linear-gradient(to top, transparent, #4caf50);
`

export default Home
