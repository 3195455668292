import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { palette } from '../assets/common'
import { Colors } from '../styles/Color'

const Loader = ({ visible, isPopup }) => {
	return (
		<Wrapper visible={visible} >
			<Img className='loading_logo_animation' src='/images/smart_soft_logo.png' alt='logo.svg' right={isPopup} />
			{/* <div> */}
			{/* <Spinner src="/images/Preloader.gif" icon="spinner" className="spinner" /> */}
			{/* </div> */}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: ${({ visible }) => (visible === true ? 'none ' : 'flex')};
	position: fixed;
	justify-content: center;
	align-items: center;
	top: 0;
	right:0;
	left: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
	background-color: ${palette.overlay};
	opacity: 0.92;
	z-index: 15;
	${media.xs`
	width: 100%;
	height: 100%;
	`}
	${media.sm`
	width: 100%;
	height: 100%;
	`}
	${media.md`
	width: 100%;
	height: 100%;
	`}
	${media.lg`
	width: 100vw;
	`}
	${media.xl`
	width: 100vw;
	`}
	${media.xxl`
	width: 100vw;
	`}
`

const Spinner = styled.img`
	color: ${Colors.White};
	background-color: transparent;
	height: 30vh;
	filter: ${({ isDarkTheme }) => (isDarkTheme ? `invert(61%) sepia(26%) saturate(741%) hue-rotate(-321deg) brightness(126%) contrast(105%)` : `invert(61%) sepia(2%) saturate(741%) hue-rotate(-321deg) brightness(126%) contrast(105%)`)};
	// filter: ${({ isDarkTheme }) => (isDarkTheme ? '#cd412b' : '#cd412b')};
`

const Img = styled.img`
	width: 70px;
	position: absolute;
	top:0;
	bottom:0;
	right:${({ right }) => (right ? '42rem !important' : '0')};
	left:0;
	margin: auto;
	${media.xs`
	position: relative;
	`}
	${media.sm`
	position: relative;
	`}
	${media.md`
	position: absolute;
	`}
	${media.lg`
	position: absolute;
	`}
	${media.xl`
	position: absolute;
	`}
	${media.xxl`
	position: absolute;
	`}
`
export default Loader
