export const lightTheme = {
	bg: 'rgb(255,255,255)',
	bgAlpha: 'rgba(250,250,250,.3)',
	bg2: 'rgb(245,245,245)',
	bg3: 'rgb(230,230,230)',
	text: 'rgb(45,45,45)',
	primary: 'rgb(52, 131, 235)'
}
export const darkTheme = {
	bg: 'rgb(15,15,15)',
	bgAlpha: 'rgba(0,0,0,.3)',
	bg2: 'rgb(30,30,30)',
	bg3: 'rgb(50,50,50)',
	text: 'rgb(210,210,210)',
	primary: 'rgb(52, 131, 235)'
}
