import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../assets/common'
import CardButton from '../components/CardButton'
import { useSelector } from 'react-redux'
import { saveUser } from '../actions/authActions'
import { fetchUserSSSTokensBalance } from '../hooks/contracts'
import { connect } from 'react-redux'
import { Flexed, Spacer, Text, InputText } from '../styles/shared'
import { BsArrowCounterclockwise } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'
import axios from 'axios'
import { ImageUrl, apiUrl } from '../config/config'
import { toast } from 'react-toastify'

const Profile = ({ saveUserHandler }) => {
	let user = useSelector((state) => state.auth.user)
	const [profileImage, setProfileImage] = useState(null)
	const tokenContract = useSelector((state) => state.auth.tokenContract)
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	const [name, setName] = useState(user?.username)
	const [secretId, setSecretId] = useState(user?.secret_id)
	const [password, setPassword] = useState(user?.password)
	const [metamaskAddress, setMetamaskAddress] = useState(user?.token)
	const [loading, setLoading] = useState(false)

	//show please wait for transaction on all buttons

	useEffect(() => {
		if (user) {
			getUserData()
		}

	}, [user])

	const getUserData = async () => {
		setLoading(true)
		await axios
			.post(`${apiUrl}/user/find`, { user: user?.token })
			.then((response) => {
				setSecretId(response?.data?.user?.secret_id)
				setPassword(response?.data?.user?.password)
				setProfileImage(response?.data?.user?.profile)
				setName(response?.data?.user?.username)
				setLoading(false)
			})
			.catch(function (error) {
				setLoading(false)
				console.log(error)
			})
	}

	const refreshSSSTokens = async () => {
		if (user) {
			const sssTokens = await fetchUserSSSTokensBalance(user.token, tokenContract)
			user.sssTokens = parseFloat(sssTokens).toFixed(2)
			saveUserHandler(user)
		}
	}

	const uploadProfile = ({ target }) => {
		const reader = new FileReader()
		reader.readAsDataURL(target.files[0])

		reader.onload = () => {
			if (reader.readyState === 2) {
				setProfileImage(reader.result)
			}
		}
	}

	const DeleteProfileImage = () => {
		setProfileImage(null)
	}

	const buySSST = async () => {
		window.open('https://pancake.kiemtienonline360.com/#/swap')
	}

	const updateUser = async () => {
		setLoading(true)
		const formData = new FormData()
		metamaskAddress && formData.append('address', metamaskAddress)
		secretId && formData.append('secret_id', secretId)
		name && formData.append('username', name)
		password && formData.append('password', password)
		profileImage && formData.append('profile', profileImage)
		const response = await fetch(`${apiUrl}/user/update`, {
			method: 'POST',
			body: formData
		})
		let res = await response.json()
		if (res?.status) {
			setLoading(false)
			await getUserData()
			toast.success('Profile Updated')
		} else if (!res?.status) {
			toast.error(res?.message ? res?.message : 'Failed to update profile')
			setLoading(false)
			return
		}

	}

	return (
		<Wrapper>
			{loading && <Loader />}
			<CustomRow id="eee">
				<Col lg={12}>
					<Form isDarkTheme={isDarkTheme}>
						<Row>
							<Col lg={12}>
								<Heading isDarkTheme={isDarkTheme}>Update Your Profile</Heading>
								<Spacer height={1} />
							</Col>
						</Row>
						<Row justifyContent="center">
							<Card>
								<NotchImg src={'/images/blue_notch.svg'} alt="notch" />
								<CardBody isDarkTheme={isDarkTheme} backGround={'blue_mirror'}>
									<SwapCard isDarkTheme={isDarkTheme}>
										<Spacer height={4} />
										<Flex direction="row" justify="center" align="center">
											<ProfileContent htmlFor="faceImage" aria-label="upload picture">
												<ProfileImage isDarkTheme={isDarkTheme} src={profileImage && profileImage?.startsWith('data:image/') ? profileImage : profileImage ? ImageUrl + profileImage : profileImage} />
											</ProfileContent>
											<FileInput accept="image/jpeg/png" id="faceImage" type="file" onChange={uploadProfile} />
										</Flex>
										{profileImage ? (
											<Flex>
												<Text bold small pointer isCentered color={isDarkTheme ? 'aqua_blue' : 'blue'} onClick={DeleteProfileImage}>
													Remove Image
												</Text>
											</Flex>
										) : null}

										<Flex border isDarkTheme={isDarkTheme} direction="row" justify="space-between" align="center">
											<Flexed direction="row" align="center">
												<Text bold xsmall color={isDarkTheme ? 'aqua_blue' : 'blue'}>
													Venture Tokens:
												</Text>
												<Text bold xsmall margin="0rem 0rem 0rem 0.2rem" isDarkTheme={isDarkTheme}>
													{user?.sssTokens ? user?.sssTokens : 0}
												</Text>
											</Flexed>
											<Flexed direction="row" align="center">
												<Text
													pointer
													bold
													xsmall
													color={isDarkTheme ? 'white' : 'blue'}
													onClick={() => {
														buySSST()
													}}>
													Buy
												</Text>
												<Icon
													isDarkTheme={isDarkTheme}
													onClick={() => {
														refreshSSSTokens()
													}}>
													<BsArrowCounterclockwise />
												</Icon>
											</Flexed>
										</Flex>
										<Spacer height={0.4} />
										<Flex border isDarkTheme={isDarkTheme} direction="row" justify="space-between" align="center">
											<Flexed direction="row" align="center">
												<Text bold xsmall color={isDarkTheme ? 'aqua_blue' : 'blue'}>
													M-Venture Tokens:
												</Text>
												<Text bold xsmall margin="0rem 0rem 0rem 0.2rem" isDarkTheme={isDarkTheme}>
													{user?.coins ? user?.coins : 0}
												</Text>
											</Flexed>
											<Link to="/mobile_tokens">
												<Text bold xsmall color={isDarkTheme ? 'white' : 'blue'}>
													Convert
												</Text>
											</Link>
										</Flex>
										<Spacer height={0.4} />
										<Label bold xsmall color={isDarkTheme ? 'aqua_blue' : 'blue'}>
											Metamask Address
										</Label>
										<TextWrapper isDarkTheme={isDarkTheme}>
											<InputText
												disabled={true}
												value={metamaskAddress}
												onChange={(e) => {
													setMetamaskAddress(e.target.value)
												}}
												isDarkTheme={isDarkTheme}
												type="text"
												placeholder="Metamask address"
											/>
										</TextWrapper>
										<Spacer height={0.4} />
										<Label bold xsmall color={isDarkTheme ? 'aqua_blue' : 'blue'}>
											Full Name
										</Label>
										<TextWrapper isDarkTheme={isDarkTheme}>
											<InputText
												value={name}
												onChange={(e) => {
													setName(e.target.value)
												}}
												isDarkTheme={isDarkTheme}
												type="text"
												placeholder="Name"
											/>
										</TextWrapper>
										<Spacer height={0.5} />
										<Label bold xsmall isCentered color={isDarkTheme ? 'white' : 'blue'}>
											Manage your login credentials for mobile games
										</Label>
										<Label bold xsmall color={isDarkTheme ? 'aqua_blue' : 'blue'}>
											Secret Id
										</Label>
										<TextWrapper isDarkTheme={isDarkTheme}>
											<InputText
												value={secretId}
												onChange={(e) => {
													setSecretId(e.target.value)
												}}
												isDarkTheme={isDarkTheme}
												autocomplete="off"
												type="text"
												placeholder="Secret Id"
											/>
										</TextWrapper>
										<Spacer height={0.4} />
										<Label bold xsmall color={isDarkTheme ? 'aqua_blue' : 'blue'}>
											Password
										</Label>
										<TextWrapper isDarkTheme={isDarkTheme}>
											<InputText
												value={password}
												onChange={(e) => {
													setPassword(e.target.value)
												}}
												isDarkTheme={isDarkTheme}
												autocomplete="off"
												type="text"
												placeholder="Password"
											/>
										</TextWrapper>

										<Spacer height={1.5} />
										<CardButton
											border
											ifClicked={() => {
												updateUser()
											}}
											isDarkTheme={isDarkTheme}
											label={'Update'}
											width={'100% !important'}
										/>
										<Spacer height={3} />
									</SwapCard>
								</CardBody>
								<NotchImgBottom src={'/images/blue_notch.svg'} alt="notch" />
							</Card>
						</Row>
						<Spacer height={2} />
					</Form>
				</Col>
			</CustomRow>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	position: relative;
	z-index: 2;
	@media screen and (min-width: 415px) and (max-width: 9999px) {
		display: flex;
		justify-content: center;
	}
`

const CustomRow = styled(Row)`
	justify-content: center;
	@media screen and (min-width: 420px) and (max-width: 500px) {
		width: 100%;
	}
	${media.sm`
        width: 100%;
    `};
	${media.md`
        width: 100%;
    `};
	${media.lg`
        width: 100%;
    `};
	${media.xl`
        width: 95%;
    `};
	${media.xxl`
        width: 90%;
    `};
`

const LightEffect = styled.div`
	width: 12rem;
	position: absolute;
	height: 9rem;
	right: 0;
	left: 0;
	margin: auto;
`

const LightEffectMiddle = styled(LightEffect)`
	bottom: 30%;
	background: ${({ backShadow }) => palette[backShadow]};
	filter: blur(20rem);
`

const Form = styled(Container)`
	padding: 2rem;
	${media.xs`
	padding:  2rem 0rem;
	`}
`

const Heading = styled.h2`
	line-height: 1.85rem;
	font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.aqua_blue : palette.blue)};
	margin: 0;
	text-align: center;
	font-size: 1rem;
	font-weight: bold;
`

const ProfileImage = styled.div`
	background: ${({ src, isDarkTheme }) => (src ? `url(${src}) no-repeat` : isDarkTheme ? '' : '')};
	background-size: cover;
	background-position: center;
	width: 7rem;
	height: 7rem;
	border-radius: 5rem;
	border: 0.063rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	margin-right: 0.3rem;
	cursor: pointer;
`

const Card = styled.div`
	display: inline-block;
	position: relative;
	margin: 0 2rem 1.8rem 2rem;
	${media.xs`
		margin: 0 0rem 1.8rem 0rem;
    `};
	${media.sm`
		margin: 0 2rem 1.8rem 2rem;
    `};
`

const CardBody = styled.div`
	clip-path: polygon(0 0, 12% 0, 28% 7%, 71% 7%, 86% 0, 100% 0, 100% 100%, 88% 100%, 70% 93%, 29% 93%, 14% 100%, 0 100%);
	display: inline-block;
	vertical-align: top;
	text-align: left;
	overflow: hidden;
	position: relative;
	background: ${({ backGround }) => palette[backGround]};
	border: none;
	font-size: 1rem;
	width: 28rem;
	min-height: 10rem;
	transition: all 0.4s ease-in-out;
	&:hover #video {
		display: block;
	}
	&:hover #image {
		display: none;
	}
	@media screen and (min-width: 0px) and (max-width: 369px) {
		width: 19rem;
	}
	@media screen and (min-width: 370px) and (max-width: 399px) {
		width: 21.5rem;
	}
	@media screen and (min-width: 400px) and (max-width: 450px) {
		width: 23rem;
	}
	@media screen and (min-width: 451px) and (max-width: 525px) {
		width: 26rem;
	}
`

const SwapCard = styled.div`
	padding: 0.5rem 0.5rem;
	margin-bottom: 2rem;
	border-radius: 1.5rem;

	box-shadow: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.green_gradient_shadow}` : `0 0 1px rgb(0 0 0 / 17%), 0 4px 8px rgb(0 0 0 / 8%), 0 8px 12px rgb(0 0 0 / 0%), 0 12px 16px rgb(0 0 0 / 2%)`)};
`

const NotchImg = styled.img`
	position: absolute;
	top: -16px;
	width: 105%;
	z-index: 2;
	left: -11px;
`

const NotchImgBottom = styled.img`
	position: absolute;
	bottom: -16px;
	width: 105%;
	z-index: 2;
	left: -11px;
	transform: rotate(180deg);
`

const Icon = styled.div`
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.off_dark}`)};
	font-size: 1.3rem;
	cursor: pointer;
	margin-left: 0.5rem;
`

const Flex = styled(Flexed)`
	padding: 0.2rem 0rem 0.5rem;
	border-bottom: ${({ isDarkTheme, border }) => (border ? (isDarkTheme ? `0.063rem solid ${palette.off_dark}` : ` 0.063rem solid ${palette.gray}`) : 'none')};
`
const Label = styled(Text)`
	padding: 0.2rem 0rem;
`
const TextWrapper = styled.div`
	border-radius: 20px;
	display: flex;
	position: relative;
	// border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.gray_light}`)};
	// background-color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.theme_Text}` : `${palette.sky}`)};
	cursor: ${({ disabled }) => (disabled ? 'no-drop' : ``)};
`

// const Input = styled.input`
// 	outline: none;
// 	border: none;
// 	background: transparent;
// 	padding: 0.3rem 0.5rem;
// 	width: 100%;
// 	font-size: 1rem;
// 	line-height: 2;
// 	color: ${palette.off_dark};
// `

const ProfileContent = styled.label``
const FileInput = styled.input`
	display: none;
`

const mapStateToProps = (state) => {
	return {
		// user: state.auth.user
	}
}

const mapDispatchToProps = (dispatch) => ({
	saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
