import { ethers } from 'ethers'
import axios from 'axios'
import { api, adminAddress } from '../config/config'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import WalletLink from 'walletlink'
import { SSSTokenContract } from '../contracts/SSSTokenContract'
import CryptoJS from 'crypto-js'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const connectWithMetamask = async (setProcessing, setAddress, setIsConnected, handleDisconnect, props, toast) => {
	try {
		const { default: MewConnectImport } = await import('@myetherwallet/mewconnect-web-client')
		const infuraId = 'd1904153f4824e368e41ecf3ac0d6418'
		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider,
				options: {
					infuraId
				}
			},
			mewconnect: {
				package: MewConnectImport,
				options: {
					infuraId
				}
			},
			'custom-coinbase': {
				display: {
					logo: '/images/coinbase_wallet.png',
					name: 'Coinbase',
					description: 'Scan with WalletLink to connect'
				},
				package: WalletLink,
				options: {
					appName: 'SSS Games',
					networkUrl: `https://mainnet.infura.io/v3/${infuraId}`,
					chainId: 1
				},
				connector: async (_, options) => {
					const { appName, networkUrl, chainId } = options
					const walletLink = new WalletLink({
						appName
					})
					const provider = walletLink.makeWeb3Provider(networkUrl, chainId)
					await provider.enable()
					return provider
				}
			}
		}
		const web3Modal = new Web3Modal({
			network: 'mainnet',
			cacheProvider: true,
			providerOptions
		})

		const web3 = await web3Modal.connect()
		const provider = new ethers.providers.Web3Provider(web3)
		const signer = provider.getSigner()
		let address = await signer.getAddress()
		address = address.toLowerCase()
		// const chainId = await signer.getChainId()
		const { chainId, name } = await provider.getNetwork()
		// if (chainId === 1 || name === 'homestead' || name === 'mainnet') {
		if (chainId.toString() == '97' || name === 'testnet') {
			setProcessing(true)
			const _connectedNetwork = await getNetworkById(chainId)

			if (_connectedNetwork) {
				let nonce = await doPreLogin(address, chainId)
				if (nonce) {

					const signature = await signer.signMessage(nonce.toString())
					const tokenContract = new ethers.Contract(SSSTokenContract.id, SSSTokenContract.abi, signer)
					let user = await doLogin(address, chainId, signature, tokenContract)
					setProcessing(false)
					if (user) {
						user.coins = parseFloat(user.coins)
						const isConnected = Boolean(provider && signer)
						if (setIsConnected) {
							setIsConnected(isConnected)
							setAddress(address)
						}
						props.setConnected({
							isConnected: isConnected,
							user: user,
							signer: signer,
							// address: address,
							tokenContract: tokenContract
						})
						toast.success('Wallet connected successfully')
						web3.on('accountsChanged', (accounts) => {
							if (handleDisconnect) {
								handleDisconnect()
							} else {
								props.setDisconnected()
							}
						})

						web3.on('chainChanged', (chainId) => {
							if (handleDisconnect) {
								handleDisconnect()
							} else {
								props.setDisconnected()
							}
						})

						web3.on('disconnect', (error) => {
							if (handleDisconnect) {
								handleDisconnect()
							} else {
								props.setDisconnected()
							}
						})
					} else {
						toast.error('Server login error. Please try again later')
						props.setConnected({
							isConnected: false
						})
					}
				}
			}
		} else {
			toast.error('Please connect to chain id 97')
			props.setConnected({
				isConnected: false
			})
		}
	} catch (error) {
		console.log('error', error)
		props.setConnected({
			isConnected: false
		})
	}
}

const doPreLogin = async (address, chainId) => {
	const response = await axios.post(`${api}/pre_login`, { address: address, chainId: chainId })
	if (response.data.status) {
		const nonce = response.data.data
		const bytes = CryptoJS.AES.decrypt(nonce, 'as@jkfhcnv<./[sdsdsds@#*(&@*!^#&@ads@,asdl-ns')
		const decrypted = bytes.toString(CryptoJS.enc.Utf8)
		return decrypted
	} else {
		return false
	}
}

const doLogin = async (address, chainId, signature, tokenContract) => {
	const response = await axios.post(`${api}/login`, {
		token: address,
		password: address,
		is_mobile: "no",
		chainId,
		signature
	})
	if (response.data.status) {
		const userSSSTokensBalance = await fetchUserSSSTokensBalance(address, tokenContract)
		response.data.data.user.sssTokens = parseFloat(userSSSTokensBalance).toFixed(2)
		return response.data.data.user
	} else {
		return false
	}
}

export const getNetworkById = async (chainId) => {
	if (chainId == '1') {
		return NetworksTypes.EthereumMainNet
	} else if (chainId == '5') {
		return NetworksTypes.EthereumTestNet
	} else if (chainId == '56') {
		return NetworksTypes.BinanceMainNet
	} else if (chainId == '97') {
		return NetworksTypes.BinanceTestNet
	} else if (chainId == '137') {
		return NetworksTypes.PolygonMainNet
	} else if (chainId == '80001') {
		return NetworksTypes.PolygonTestNet
	} else {
		return false
	}
}

export const NetworksTypes = Object.freeze({
	EthereumMainNet: {
		title: 'Ethereum Mainnet',
		chainId: '1',
		icon: '/images/ethereum.png',
		mainnet: true,
		symbol: 'ETH',
		networkUrl: '',
		tokenUrl: '',
		swapUrl: 'https://app.uniswap.org/#/swap?outputCurrency=',
		exchange: 'UniSwap',
		rpcProvider: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
	},
	EthereumTestNet: {
		// title: 'Ethereum Mainnet',
		title: 'Ethereum Testnet Goerli',
		chainId: '5',
		icon: '/images/ethereum.png',
		mainnet: false,
		symbol: 'ETH',
		networkUrl: 'https://goerli.etherscan.io/address',
		tokenUrl: 'https://goerli.etherscan.io/token',
		swapUrl: 'https://app.uniswap.org/#/swap?outputCurrency=',
		exchange: 'UniSwap',
		rpcProvider: `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
	},
	BinanceMainNet: {
		title: 'Binance Smart Chain Mainnet',
		chainId: '56',
		icon: '/images/binance.png',
		mainnet: true,
		symbol: 'BNB',
		networkUrl: 'https://bscscan.com/address',
		tokenUrl: 'https://bscscan.com/token',
		swapUrl: 'https://pancakeswap.finance/swap?outputCurrency=',
		exchange: 'PancakeSwap',
		rpcProvider: 'https://bsc-dataseed.binance.org'
	},
	BinanceTestNet: {
		// title: 'Binance Smart Chain Mainnet',
		title: 'Binance Smart Chain Testnet',
		chainId: '97',
		icon: '/images/binance.png',
		mainnet: false,
		symbol: 'BNB',
		networkUrl: 'https://testnet.bscscan.com/address',
		tokenUrl: 'https://testnet.bscscan.com/token',
		swapUrl: 'https://pancake.kiemtienonline360.com/#/swap?outputCurrency=',
		exchange: 'PancakeSwap',
		rpcProvider: 'https://data-seed-prebsc-1-s1.binance.org:8545'
	},
	PolygonMainNet: {
		title: 'Polygon Mainnet',
		chainId: '137',
		icon: '/images/polygon.png',
		mainnet: true,
		symbol: 'Matic',
		networkUrl: '',
		tokenUrl: '',
		swapUrl: 'https://quickswap.exchange/#/swap?outputCurrency=',
		exchange: 'QuickSwap',
		rpcProvider: 'https://polygon-rpc.com'
	},
	PolygonTestNet: {
		// title: 'Polygon Mainnet',
		title: 'Polygon Testnet Mumbai',
		chainId: '80001',
		icon: '/images/polygon.png',
		mainnet: false,
		symbol: 'Matic',
		networkUrl: 'https://mumbai.polygonscan.com/address',
		tokenUrl: 'https://mumbai.polygonscan.com/token',
		swapUrl: 'https://quickswap.exchange/#/swap?outputCurrency=',
		exchange: 'QuickSwap',
		rpcProvider: 'https://rpc-mumbai.maticvigil.com'
	}
})

//todo..make it secure
const doUpdateUserTokens = async (u_id, amount) => {
	let form = new FormData()
	form.append('u_id', u_id)
	form.append('coins', amount)
	form.append('type', 'add')
	form.append('c_id', '-1')
	const response = await axios.post(`${api}/update_user_coins`, {
		u_id: u_id,
		coins: amount,
		type: 'add',
		c_id: '-1'
	})
	if (response.data.status) {
		return true
	} else {
		return false
	}
}

const fetchUserSSSTokensBalance = async (address, tokenContract) => {
	let balance = await tokenContract.balanceOf(address)
	balance = ethers.utils.formatEther(balance)
	return balance
}

const convertSSSTokensToMobileTokens = async (address, amount, tokenContract) => {
	try {
		const tokenApproval = await tokenContract.approve(address, ethers.utils.parseUnits(amount).toString())
		await tokenApproval.wait()
		if (tokenApproval) {
			const transaction = await tokenContract.transferFrom(address, adminAddress, ethers.utils.parseUnits(amount).toString())
			const response = await transaction.wait()
			//todo..check how many transferred from events
			console.log(response)
			return response
		} else {
			return false
		}
	} catch (error) {
		return false
	}
}

export { connectWithMetamask, doUpdateUserTokens, fetchUserSSSTokensBalance, convertSSSTokensToMobileTokens }
