import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import './index.css'

import {createStore} from 'redux'
import rootReducer from './reducers/index'
import {GridThemeProvider} from 'styled-bootstrap-grid'
import {ThemeProvider} from 'styled-components'

const store = createStore(rootReducer)
const gridTheme = {
	gridColumns: 12, // default 12
	breakpoints: {
		// defaults below
		xxl: 1440,
		xl: 1200,
		lg: 992,
		md: 768,
		sm: 576,
		xs: 575
		// or you can use aliases
		// veryGiant: 1440,
		// giant: 1200,
		// desktop: 992,
		// tablet: 768,
		// phone: 576,
		// smaller: 575,
	},
	row: {
		padding: 15 // default 15
	},
	col: {
		padding: 15 // default 15
	},
	container: {
		padding: 15, // default 15
		maxWidth: {
			// defaults below
			xxl: 1700,
			xl: 1700,
			lg: 1700,
			md: 1700,
			sm: 1700,
			xs: 1700
			// or you can use aliases
			// veryGiant: 1141,
			// giant: 1140,
			// desktop: 960,
			// tablet: 720,
			// phone: 540,
			// smaller: 540,
		}
	}
}
export const styledTheme = {
	mainColor: 'purple'
}

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<ThemeProvider theme={styledTheme}>
				<GridThemeProvider gridTheme={gridTheme}>
					<App />
				</GridThemeProvider>
			</ThemeProvider>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
)
