export const palette = {
	shadow: '0px 3px 15px rgba(255, 255, 255, 0.2)',
	pure_white: '#ffffff',
	header_black: 'rgba(4, 6, 9, 1)',
	header_black_transparent: 'rgb(4, 6, 9, 0.8)',
	white: '#f0f0f0',
	silver: '#2f3032',
	pie_chart_bg: '#09080a',
	binance: '#F3BA2F',
	polygon: '#8248e5',
	ether: '#8d8d8d',
	off_white: '#fafafa',
	theme_Text: '#8c9b94',
	description_Text: '#c1c1c1',
	black: '#040609',
	black2: '#000000b0',
	off_black: '#241E27',
	light: '#f5f5f5',
	grapes: '#2E2732',
	dark: '#191a1c',
	off_dark: '#2d2d2d',
	hot: '#ed3c16',
	blue_hover: '#166fe5',
	sky: 'rgb(247, 248, 250)',
	cloud: 'rgb(237, 238, 242)',
	gray: '#e3e2e2',
	dark_gray: '#b9babb',
	gray_light: '#00000021',
	charcol: '#b8b8b8',
	charcol_light: '#585858',
	secondary: '#e91e63',
	secondary_light: '#fdeaf1',
	twitter: '#1d9bf0',
	telegram: '#1e8fcc',
	discord: '#485ef4',
	world_map: '#9c27b0',
	overlay: '#00000087',
	glowing_blue: '#00e0ff',
	aqua_blue: '#2596be',
	aqua_blue_bright: '#00dfff',
	blue: '#009eff',
	lightAqua: '#DDFBFF',
	heading: '#EFECEC',
	yellow: '#FFF500',
	pink: '#FF00E5',
	red: '#FF0000',
	green: '#71F063',
	orange: '#FF8F51',
	blue_gradient_card: 'linear-gradient(to right,#00314e87, #024665b8 , #00314e87)',
	gray_gradient_card: 'linear-gradient(to right,#70707052, #8c8c8cbd , #70707052)',
	blue_gradient_wing: 'radial-gradient(farthest-corner at 54% 100%,#03adf7,#00dfff)',
	green_mirror: '#71f0630a',
	blue_mirror: '#0085ff0a',
	orange_mirror: '#ff6c000a',
	gray_mirror: '#ffffff26',
	pink_mirror: '#a981bc26',
	red_mirror: '#ff00000a',
	yellow_mirror: '#faff000a',
	green_gradient: 'linear-gradient(91.88deg,#0C7D00 5.72%,#71F063 95.59%)',
	blue_gradient: 'linear-gradient(91.88deg,#0085FF 5.72%,#00DFFF 95.59%)',
	gray_gradient: 'linear-gradient(91.88deg,#b2b2b2 5.72%,#ffffff 95.59%)',
	pink_gradient: 'linear-gradient(91.88deg,#Af05ff 5.72%,#FF00E5 95.59%)',
	orange_gradient: 'linear-gradient(91.88deg,#FF6007 5.72%,#FF8F51 95.59%)',
	red_gradient: 'linear-gradient(91.88deg,#a30400 5.72%,#FF0000 95.59%)',
	yellow_gradient: 'linear-gradient(91.88deg,#ffc107 5.72%,#FFF500 95.59%)',
	green_gradient_shadow: 'linear-gradient(180deg, rgba(66, 255, 0, 0.3) 0%, rgba(219, 255, 0, 0.3) 100%);',
	pink_gradient_shadow: 'linear-gradient(166.96deg, rgba(250, 0, 255, 0.5) 9.41%, rgba(103, 0, 120, 0.45) 176.63%);',
	orange_gradient_shadow: 'linear-gradient(166.96deg, #ff8c48 9.41%, #ff8641 176.63%);',
	gray_gradient_shadow: 'linear-gradient(166.96deg, rgba(255, 255, 255, 0.58) 9.41%, rgba(255, 255, 255, 0.58) 176.63%);',
	blue_gradient_shadow: 'linear-gradient(159.04deg, rgba(0, 223, 255, 0.5) 13.85%, rgba(0, 121, 209, 0.5) 95.84%);',
	red_gradient_shadow: 'linear-gradient(159.04deg,#f00 13.85%,#d10000 95.84%)',
	yellow_gradient_shadow: 'linear-gradient(159.04deg,#ffe20080 13.85%,#bdd10080 95.84%)',
	shaded_red_Gradient: 'linear-gradient(90deg, rgba(205,65,43,1) 0%, rgba(255,255,255,1) 100%)',
	light_green: '#8e918e',
	light_red: '#cd412b',
	light_aqua: '#d76755 ',
	hesla_white: 'hsl(0deg 0% 100% / 70%)',
	off_black: '#161814'
}

export const Colors = {
	Black: 'black',
	White: 'white',
	TransparentGray: 'rgba(0, 0, 0, 0.1)',
	Gray: '#7F7F7F',
	Red: 'red',
	Green: 'green',
	Yellow: 'yellow',
	Nobel: '#B3B3B3',
	DoveGray: '#666666',
	GradientColor: '110.78deg, rgb(118, 230, 80) -1.13%, rgb(249, 214, 73) 15.22%, rgb(240, 142, 53) 32.09%, rgb(236, 81, 87) 48.96%, rgb(255, 24, 189) 67.94%, rgb(26, 75, 255) 85.34%, rgb(98, 216, 249) 99.57%',
	Blur: 'rgba(255, 255, 255, 0.2)',
	LightBlack: '#4D4D4D',
	LightGray: '#f5f5f5',
	DarkGray: '#666666',
	Gold: 'goldenrod',
	Blue: 'blue',
	OffDark: '#2d2d2d',
	BlueLight: '#e7f3ff',
	LighterGray: '#eee'
}

export const PaletteNew = {
	shadedRed: '#2596be',
	graniteGray: '#606460',
	darkCharcoal: '#333833',
	paleWhite: '#ffffffb3',
	lightBlue: '#b2d6ef',
	lightGreen: '#c8e6c9',
	lightRed: '#d76755',
	gray92: '#ebedeb',
	green: '#66852f',
	darkCyanBlue: '#1f435b',
	darkGreen: '#485f25',
	blue: '#265c81',
	gray: '#505551',
	light_gray_hover: '#2b312a',
	white: '#ffffff'
}
