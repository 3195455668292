import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../assets/common'
import CardButton from '../components/CardButton'
import { useSelector } from 'react-redux'
import { setConnected, setDisconnected, saveUser } from '../actions/authActions'
import { connectWithMetamask, doUpdateUserTokens, fetchUserSSSTokensBalance, convertSSSTokensToMobileTokens } from '../hooks/contracts'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Flexed, Spacer, Text, InputText } from '../styles/shared'
import { BsArrowCounterclockwise } from 'react-icons/bs'
import { AiOutlineArrowDown } from 'react-icons/ai'
import Loader from '../components/Loader'
import { apiUrl } from '../config/config'
import axios from 'axios'

const MobileTokens = (props) => {
	let user = useSelector((state) => state.auth.user)
	const tokenContract = useSelector((state) => state.auth.tokenContract)
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)

	const [amount, setAmount] = useState(0)
	const [ssst, setSSST] = useState(user?.sssTokens ? user?.sssTokens : 0)
	const [mSSST, setMSSST] = useState(user?.coins ? user?.coins : 0)
	const [transactionInProcesss, setTransactionInProcesss] = useState(false)
	const [processing, setProcessing] = useState(false)
	const _isConnected = useSelector((state) => state.auth.isConnected)

	useEffect(() => {
		if (user) {
			getUserData()
			refreshSSSTokens()
		} else if (_isConnected == false) {
			setSSST(0)
			setMSSST(0)
		}

	}, [user, _isConnected])

	const getUserData = async (loading = true) => {
		setTransactionInProcesss(loading)
		await axios
			.post(`${apiUrl}/user/find`, { user: user?.token })
			.then((response) => {
				setSSST(user?.sssTokens ? user?.sssTokens : 0)
				setMSSST(response?.data?.user?.coins ? response?.data?.user?.coins : 0)
				setTransactionInProcesss(false)
			})
			.catch(function (error) {
				setTransactionInProcesss(false)
				console.log(error)
			})
	}

	const convertToMobileTokens = async () => {
		if (!user) {
			connectWithMetamask(setProcessing, null, null, null, props, toast)
		} else {
			if (amount <= parseFloat(user.sssTokens)) {
				setTransactionInProcesss(true)
				const result = await convertSSSTokensToMobileTokens(user.token, amount.toString(), tokenContract)
				if (result) {
					user.sssTokens = parseFloat(user.sssTokens - amount).toFixed(2)
					user.coins = (parseFloat(user.coins) + parseFloat(amount)).toFixed(2)
					props.saveUserHandler(user)
					await doUpdateUserTokens(user.id, amount)
					setSSST(user.sssTokens)
					setMSSST(user.coins)
					setAmount(0)
					refreshSSSTokens()
					getUserData()
					toast.success(`${amount} SSST converted succesfully to M-SSST`)
				} else {
					toast.error('Transaction failed. Please allow the token transfer or make sure you have requried amount of tokens')
				}
				setTransactionInProcesss(false)
			} else {
				setTransactionInProcesss(false)
				toast.error('You do not have requried amount of tokens. Please refresh your SSST or purchase SSST.')
			}
		}
	}

	const refreshSSSTokens = async () => {
		if (user) {
			setTransactionInProcesss(true)
			const sssTokens = await fetchUserSSSTokensBalance(user.token, tokenContract)
			user.sssTokens = parseFloat(sssTokens).toFixed(2)
			props.saveUserHandler(user)
			setSSST(user.sssTokens)
			setTransactionInProcesss(false)
		}
	}

	const handlePriceValidation = (e) => {
		let decimal_regex = /^[.0-9]+$/
		const value = decimal_regex.test(e) ? e : ''
		setAmount(value)
	}

	return (
		<Wrapper>
			{transactionInProcesss && <Loader />}
			<CustomRow>
				<Col lg={12}>
					<Form isDarkTheme={isDarkTheme}>
						<Row>
							<Col lg={12}>
								<Heading isDarkTheme={isDarkTheme}>What is M-Venture?</Heading>
								<Spacer height={1} />
							</Col>
						</Row>
						<Row justifyContent="center">
							<Card>
								<NotchImg src={'/images/blue_notch.svg'} alt="notch" />
								<CardBody isDarkTheme={isDarkTheme} backGround={'blue_mirror'}>
									<Row>
										<Col lg={12}>
											<Spacer height={5} />
											<Text xsmall isDarkTheme={isDarkTheme} isCentered margin="0rem 1rem">
												M-Venture serves as the virtual token designated for mobile use. Players can utilize it to engage in mobile gaming.
											</Text>
										</Col>
									</Row>
									<Spacer height={0.5} />
									<Row justifyContent="center">
										<SwapCard isDarkTheme={isDarkTheme}>
											<Flex border isDarkTheme={isDarkTheme} direction="row" justify="space-between" align="center">
												<Flexed direction="row" align="center">
													<Text xsmall bold color={isDarkTheme ? 'aqua_blue' : 'blue'}>
														My Venture Tokens:
													</Text>
													<Text xsmall bold margin="0rem 0rem 0rem 0.2rem" isDarkTheme={isDarkTheme}>
														{ssst}
													</Text>
												</Flexed>
												<Icon
													isDarkTheme={isDarkTheme}
													onClick={() => {
														refreshSSSTokens()
													}}>
													<BsArrowCounterclockwise />
												</Icon>
											</Flex>
											<Flex border isDarkTheme={isDarkTheme} direction="row" justify="space-between" align="center">
												<Flexed direction="row" align="center">
													<Text xsmall bold color={isDarkTheme ? 'aqua_blue' : 'blue'}>
														My M-Venture Tokens:
													</Text>
													<Text xsmall bold margin="0rem 0rem 0rem 0.2rem" isDarkTheme={isDarkTheme}>
														{mSSST}
													</Text>
												</Flexed>
											</Flex>
											<Flex direction="row" justify="center" align="center">
												<Text bold small color={isDarkTheme ? 'white' : 'off_dark'}>
													Convert
												</Text>
											</Flex>

											<TextWrapper isDarkTheme={isDarkTheme}>
												<InputText
													value={amount}
													onChange={(e) => {
														handlePriceValidation(e.target.value)
													}}
													isDarkTheme={isDarkTheme}
													placeholder="0.0"
													style={{ paddingRight: '8rem' }}
													type="number"
													min="0"
													step="1"
													pattern="\d+"
												/>
												<Currency isDarkTheme={isDarkTheme} direction="row" align="center">
													<CurrencyIcon src="/images/m_ssst.png" isDarkTheme={isDarkTheme} alt="ssst-token" />
													<CurrencyName bold small color={isDarkTheme ? 'aqua_blue' : 'off_dark'}>
														Venture
													</CurrencyName>
												</Currency>
												<Arrow isDarkTheme={isDarkTheme}>
													{' '}
													<AiOutlineArrowDown />
												</Arrow>
											</TextWrapper>

											<Spacer height={0.5} />

											<TextWrapper isDarkTheme={isDarkTheme} disabled>
												<InputText value={amount} isDarkTheme={isDarkTheme} type="text" placeholder="0.0" disabled style={{ paddingRight: '8rem' }} />
												<Currency isDarkTheme={isDarkTheme} direction="row" align="center">
													<CurrencyIcon src="/images/m_ssst.png" isDarkTheme={isDarkTheme} alt="mssst-token" />
													<CurrencyName bold small color={isDarkTheme ? 'aqua_blue' : 'off_dark'}>
														M-Venture
													</CurrencyName>
												</Currency>
											</TextWrapper>

											<Spacer height={1.5} />
											<CardButton
												type="primary"
												border
												ifClicked={() => {
													convertToMobileTokens()
												}}
												isDarkTheme={isDarkTheme}
												label={processing ? 'Connecting...' : user ? 'Convert' : 'Connect Wallet'}
												width={'100% !important'}
											/>
											<Spacer height={3} />
										</SwapCard>
									</Row>
								</CardBody>
								<NotchImgBottom src={'/images/blue_notch.svg'} alt="notch" />
							</Card>
						</Row>
					</Form>
				</Col>
			</CustomRow>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	position: relative;
	z-index: 2;
	@media screen and (min-width: 415px) and (max-width: 9999px) {
		display: flex;
		justify-content: center;
	}
`

const CustomRow = styled(Row)`
	justify-content: center;
	${media.xl`
        width: 95%;
    `};
	${media.xxl`
        width: 90%;
    `};
`

const LightEffect = styled.div`
	position: absolute;
	background: ${palette.blue_gradient_shadow};
	width: 4rem;
	height: 7rem;
	filter: blur(4rem);
	left: 0;
	right: 0;
	margin: auto;
`

const NotchImg = styled.img`
	position: absolute;
	top: -16px;
	width: 104.7%;
	z-index: 2;
	left: -10px;
`

const NotchImgBottom = styled.img`
	position: absolute;
	bottom: -16px;
	width: 104.7%;
	z-index: 2;
	left: -10px;
	transform: rotate(180deg);
`

const NocthDotTop = styled.img`
	position: absolute;
	top: -0.3rem;
	z-index: 2;
	width: 0.7rem;
`

const NocthDotBottom = styled.img`
	position: absolute;
	bottom: -0.3rem;
	z-index: 2;
	width: 0.7rem;
`

const Card = styled.div`
	display: inline-block;
	position: relative;
	margin: 0 2rem 1.8rem 2rem;
	${media.xs`
		margin: 0 0rem 1.8rem 0rem;
    `};
	${media.sm`
		margin: 0 2rem 1.8rem 2rem;
    `};
`

const CardBody = styled(Container)`
	clip-path: polygon(0 0, 12% 0, 28% 7%, 71% 7%, 86% 0, 100% 0, 100% 100%, 88% 100%, 70% 93%, 29% 93%, 14% 100%, 0 100%);
	display: inline-block;
	vertical-align: top;
	text-align: left;
	overflow: hidden;
	position: relative;
	background: ${({ backGround }) => palette[backGround]};
	border: none;
	font-size: 1rem;
	width: 26rem;
	min-height: 10rem;
	transition: all 0.4s ease-in-out;
	&:hover #video {
		display: block;
	}
	&:hover #image {
		display: none;
	}
	@media screen and (min-width: 0px) and (max-width: 369px) {
		width: 19rem;
	}
	@media screen and (min-width: 370px) and (max-width: 399px) {
		width: 21.5rem;
	}
	@media screen and (min-width: 400px) and (max-width: 450px) {
		width: 23rem;
	}
`

const Form = styled(Container)`
	padding: 2rem;
	${media.xs`
	padding:  2rem 0rem;
	`}
`

const Heading = styled.h2`
	line-height: 1.85rem;
	font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.aqua_blue : palette.blue)};
	margin: 0;
	text-align: center;
	font-size: 1rem;
	font-weight: bold;
`

const SwapCard = styled.div`
	width: 100%;
	padding: 0.5rem 0.5rem;
	margin-bottom: 2rem;
	border-radius: 1.5rem;
`
const Icon = styled.div`
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.off_dark}`)};
	font-size: 1.3rem;
	cursor: pointer;
`

const Flex = styled(Flexed)`
	padding: 8px 0px;
	border-bottom: ${({ isDarkTheme, border }) => (border ? (isDarkTheme ? `0.063rem solid ${palette.off_dark}` : ` 0.063rem solid ${palette.gray}`) : 'none')};
`

const TextWrapper = styled.div`
	border-radius: 20px;
	display: flex;
	padding: 0 0 0 0.5rem;
	position: relative;
	// border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.gray_light}`)};
	// background-color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.theme_Text}` : `${palette.sky}`)};
	cursor: ${({ disabled }) => (disabled ? 'no-drop' : ``)};
`

// const Input = styled.input`
// 	outline: none;
// 	border: none;
// 	background: transparent;
// 	padding: 0rem 0.4rem;
// 	width: 100%;
// 	font-size: 1.2rem;
// 	color: ${palette.off_dark};
// `

const Currency = styled(Flexed)`
	border-radius: 20px;
	min-width: 8rem;
	position: absolute;
	right: 0;
	padding: 0.4rem 0.7rem 0.4rem 0.5rem;
	// border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	// background-color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.charcol_light}` : `${palette.cloud}`)};
`

const CurrencyIcon = styled.div`
	background: ${({ src, isDarkTheme }) => (src ? `url(${src}) no-repeat` : isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	background-size: cover;
	background-position: center;
	width: 1.75rem;
	height: 1.75rem;
	border-radius: 5rem;
	border: 0.063rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	margin-right: 0.3rem;
`

const CurrencyName = styled(Text)`
	white-space: pre;
`

const Arrow = styled.div`
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.black2}` : `${palette.off_dark}`)};
	position: absolute;
	bottom: -22px;
	width: 2rem;
	right: 0;
	left: 0;
	margin: auto;
	text-align: center;
	border: 0.3rem solid ${({ isDarkTheme }) => (isDarkTheme ? `${palette.dark}` : `${palette.white}`)};
	z-index: 2;
	background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.sky}`)};
	border-radius: 0.6rem;
	// cursor: pointer;
`

const mapStateToProps = (state) => {
	return {
		user: state.auth.user
	}
}

const mapDispatchToProps = (dispatch) => ({
	setConnected: (data) => dispatch(setConnected(data)),
	setDisconnected: (data) => dispatch(setDisconnected(data)),
	saveUserHandler: (data) => dispatch(saveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileTokens)
