import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../styles/Color.js'
import { useSelector } from 'react-redux'
import { Spacer } from '../styles/shared.js'

const Footer = () => {
	const isDarkTheme = useSelector((state) => state.auth.isDarkTheme)
	return (
		<>
			<Wrapper>
				<Row justifyContent="center">
					<CustomCol>
						<Text isDarkTheme={isDarkTheme} href="#;">
							Policy
						</Text>
						<Text isDarkTheme={isDarkTheme} href="#;">
							Terms & Conditions
						</Text>
					</CustomCol>
				</Row>
				<Spacer height={1} />
				<Row>
					<CustomCol>
						<Flex>

							<Linker href={`https://twitter.com/`} rel="noreferrer" target="_blank">
								<SocialIcon src="/images/twitter.png" />
							</Linker>
							<Linker href={`https://testnet.bscscan.com/`} rel="noreferrer" target="_blank">
								<SocialIcon src="/images/web.png" />
							</Linker>
							<Linker href={`https://web.telegram.org/`} rel="noreferrer" target="_blank">
								<SocialIcon src="/images/telegram.png" />
							</Linker>
							<Linker href={`https://discord.com/`} rel="noreferrer" target="_blank">
								<SocialIcon src="/images/discord.png" />
							</Linker>
							<Spacer height={1} />
						</Flex>
					</CustomCol>
				</Row>
			</Wrapper>
		</>
	)
}
const Wrapper = styled(Container)`
	margin-top: auto;
	border-top: 0.1rem solid ${palette.gray_light};
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	max-width: 100%;
	position: relative;
	z-index: 2;
`

const CustomCol = styled(Col)`
	display: flex;
	justify-content: center;
	@media screen and (min-width: 200px) and (max-width: 450px) {
		display: grid;
		/* grid-template-columns: 1fr 1fr; */
		gap: 1rem;
	} ;
`

const Text = styled.a`
	font-family: 'bicubik', sans-serif;
	letter-spacing: 0.1em;
	font-size: 0.8rem;
	display: inline-block;
	&:not(:last-child) {
		margin-right: 1rem;
	}
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	text-decoration: none;
	&:hover {
		color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.aqua_blue}` : `${palette.blue}`)};
	};
	${media.xs`
	font-size: 0.5rem;
	&:not(:last-child) {
		margin-right: 0.4rem;
	}
  `}
	${media.sm`
	font-size: 0.6rem;
	&:not(:last-child) {
		margin-right: 0.4rem;
	}
   `}
   ${media.md`
   font-size: 0.8rem;
   &:not(:last-child) {
		margin-right: 1rem;
	}
  `}
	${media.lg`
	font-size: 0.8rem;
	&:not(:last-child) {
		margin-right: 1rem;
	}
   `}
`

const Flex = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`

const Linker = styled.a``

const SocialIcon = styled.img`
	height: 1.5rem;
	margin-right: 0.5rem;
	width: 1.5rem;
`

export default Footer
