import React, { useState } from 'react'
import './App.css'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import Layout from './components/Layout'
import Home from './pages/Home'
import Packages from './pages/Packages'
import Tokens from './pages/Tokens'
import MobileTokens from './pages/MobileTokens'
import Footer from './components/Footer'
import { Route, Routes, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { GlobalStyle } from './styles/globalStyles'
import { darkTheme, lightTheme } from './styles/theme'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Profile from './pages/Profile'

export const ThemeContext = React?.createContext(null)

function App() {
	const { pathname } = useLocation()
	const [theme, setTheme] = useState('light')
	const themeStyle = theme === 'light' ? lightTheme : darkTheme

	return (
		<ThemeContext.Provider value={{ setTheme, theme }}>
			<ThemeProvider theme={themeStyle}>
				<GlobalStyle />
				<Layout>
					<AppContent className="App" value={pathname === '/'}>
						<Routes>
							<Route path="/" element={<Home />}></Route>
							<Route path="/tokens" element={<Tokens />}></Route>
							<Route path="/mobile_tokens" element={<MobileTokens />}></Route>
							<Route path="/packages" element={<Packages />}></Route>
							<Route path="/profile" element={<Profile />}></Route>
						</Routes>
						<Footer />
						<ToastContainer position="top-center" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
					</AppContent>
				</Layout>
			</ThemeProvider>
		</ThemeContext.Provider>
	)
}

const AppContent = styled.div`
	padding-top: ${({ value }) => value ? '5rem' : '6rem'};

`

export default App
